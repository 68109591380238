import './styles.scss';

import { CustomPagination } from '@components/modules/pagination';
import Response from '@data-access/responses/response';
import { Payout } from '@interfaces/payouts';
import { CreateButton, mapAntdSorterToCrudSorting } from '@refinedev/antd';
import {
    useApiUrl,
    useCreate,
    useCustom,
    useGetIdentity,
    useOne,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { Card, Col, notification, Popconfirm, Row, Space, Spin, Statistic, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { convertPrice } from 'utils/resource';

import { DownloadPrint } from '../../../components/contact/download-pdf';
import { OtpConfirm } from '../../../components/modules/otp-confirm';
import { DateRangeFilter } from '../../../components/reports/settlements/date-range-filter';
import { OTP_RESOURCE_NAME, PAGE_SIZE } from '../../../constants/index.constant';
import { PERMISSIONS } from '../../../constants/permission';
import { useFilterContext } from '../../../contexts/filter.context';
import { ExportTypeEnum } from '../../../enums/export-file.enum';
import { Otp } from '../../../interfaces/otp';
import { Payment } from '../../../interfaces/payment';
import { IUser } from '../../../interfaces/user';

interface IProps {
    name?: string;
}

type PayoutData = {
    data: {
        payouts: Payout.IPayoutWL[];
        totalPayout: number;
    };
    total: number;
};

export const PAYOUT_RESOURCE_API = 'v1/payouts';
const PAYOUT_ACCOUNT_RESOURCE_API = 'v1/payments';

export const PayoutListIndex: React.FC<IProps> = () => {
    const translate = useTranslate();
    const [activeWithdraw, setActiveWithdraw] = useState<boolean>(false);
    const { mutate: sendOtp, isLoading: isSendingOtp } = useCreate<Response<string>>();
    const { data: permissions } = usePermissions<string[]>();
    const { mutate, isLoading } = useCreate();
    const { data: currentUser } = useGetIdentity<IUser.IUserDto>();
    const [isOpenOtp, setIsOpenOtp] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [fieldSorter, setFieldSorter] = useState<string>('');
    const [sorter, setSorter] = useState<'asc' | 'desc'>('asc');
    const apiUrl = useApiUrl();

    const { filter, onSetDateRange } = useFilterContext();
    const timeZone = moment.tz.guess();
    const {
        data: payoutData,
        isFetching,
        refetch: getPayouts,
    } = useCustom<Response<PayoutData>>({
        url: `${apiUrl}/${PAYOUT_RESOURCE_API}`,
        method: 'get',
        queryOptions: { enabled: false },
        config: {
            filters: [
                { field: 'dateRange', operator: 'eq', value: filter.dateRange },
                { field: 'timeZone', operator: 'eq', value: timeZone },
                {
                    field: 'pageSize',
                    operator: 'eq',
                    value: pageSize,
                },
                {
                    field: 'pageIndex',
                    operator: 'eq',
                    value: pageIndex,
                },
            ],
            sort: [
                {
                    field: fieldSorter,
                    order: sorter,
                },
            ],
        },
    });

    const { data: paymentAccount, isFetched } = useOne<Payment.IPaymentAccount>({
        resource: `${PAYOUT_ACCOUNT_RESOURCE_API}/account-link`,
        id: '',
    });

    const createWithdraw = () => {
        mutate(
            {
                resource: `${PAYOUT_RESOURCE_API}/withdraw`,
                values: {},
                successNotification: {
                    message: translate('payouts.withdraw_successfully'),
                    type: 'success',
                },
                errorNotification: false,
            },
            {
                onError(error) {
                    if (error?.message) {
                        notification.error({
                            message: error?.message
                                ? error?.message
                                : translate('payouts.withdraw_failed'),
                            type: 'error',
                        });
                    }
                },
                onSuccess() {
                    getPayouts();
                },
            },
        );
    };

    const handleAfterVerifyOtp = () => {
        createWithdraw();
    };

    useEffect(() => {
        if (isFetched) {
            if (
                paymentAccount?.data &&
                paymentAccount?.data?.payoutsEnabled &&
                paymentAccount?.data?.capabilities?.cardPayments === 'active' &&
                paymentAccount?.data?.capabilities?.transfers === 'active' &&
                (paymentAccount?.data?.capabilities?.totalPendingPayoutAmount ?? 0) > 0
            ) {
                setActiveWithdraw(true);
            } else {
                setActiveWithdraw(false);
            }
        }
    }, [paymentAccount?.data]);

    useEffect(() => {
        setPageIndex(1);
        getPayouts();
    }, [filter.dateRange]);

    useEffect(() => {
        getPayouts();
    }, [pageIndex, pageSize, fieldSorter, sorter]);

    const handleSendOtp = () => {
        const payload: Otp.ISendOtp = {
            id: currentUser?.id ?? '',
            resourceName: OTP_RESOURCE_NAME.PAYOUT,
            email: currentUser?.email ?? '',
        };
        sendOtp(
            {
                resource: 'v1/otp/code',
                values: payload,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    setIsOpenOtp(true);
                },
                onError: (error, _, __) => {
                    if (error.statusCode === 401) {
                        notification.error({
                            message: translate('otp.errors.item_is_unauthorized', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }

                    if (error.message === 'can_not_create_otp_code') {
                        notification.error({
                            message: translate('otp.errors.can_not_create_otp_code', {
                                ns: 'common',
                            }),
                            type: 'error',
                        });
                        return;
                    }
                    notification.error({
                        message: translate('otp.errors.send_otp_failed', { ns: 'common' }),
                        type: 'error',
                    });
                },
            },
        );
    };
    const columns: ColumnsType<Payout.IPayoutWL> = [
        {
            title: translate('payouts.invoice_id'),
            dataIndex: 'invoiceIncrementId',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.invoiceIncrementId}</div>;
            },
        },
        {
            title: translate('payouts.product_name'),
            dataIndex: 'productName',
            render: (_, item) => {
                return <div className="table-tbody-text">{item?.productName}</div>;
            },
        },
        {
            title: translate('payouts.status'),
            dataIndex: 'status',
            sorter: (a, b) => ('' + a.status).localeCompare(b.status as any),
            render: (_, item) => {
                return (
                    <div className="table-tbody-text">
                        {translate('payouts.status_enum.' + item?.status)}
                    </div>
                );
            },
        },
        {
            title: translate('payouts.margin'),
            dataIndex: 'margin',
            render: (margin: number) => {
                return <div className="table-tbody-text">{margin > 0 ? margin : '--'}</div>;
            },
        },
        {
            title: translate('payouts.amount'),
            dataIndex: 'amount',
            render: (_, item) => {
                return <div className="table-tbody-text">{convertPrice(item?.totalAmount)}</div>;
            },
        },
    ];
    const _payoutData = payoutData?.data as unknown as PayoutData;

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (Object.keys(sorter).length > 0) {
            const crudSorting = mapAntdSorterToCrudSorting(sorter);

            if (crudSorting.length) {
                setSorter(crudSorting[0].order);
                setFieldSorter(crudSorting[0].field);
            } else {
                setSorter('asc');
                setFieldSorter('name');
            }
        }
    };

    return (
        <Spin spinning={isFetching}>
            <section className="payouts-container w-full h-full">
                <>
                    <Row className="payouts-row mb-6 items-center">
                        {activeWithdraw &&
                        (permissions || []).includes(PERMISSIONS.WL_PAYOUTS_CREATE) ? (
                            <Col xs={24} className="flex justify-end btn-withdraw">
                                <Popconfirm
                                    placement="bottomRight"
                                    title={translate('payouts.withdraw_confirm')}
                                    onConfirm={handleSendOtp}
                                    okText={translate('payouts.yes')}
                                    cancelText={translate('payouts.cancel')}
                                >
                                    <CreateButton icon={false} type="primary" loading={isLoading}>
                                        {translate('payouts.withdraw')}
                                    </CreateButton>
                                </Popconfirm>
                            </Col>
                        ) : null}
                        <Col xs={24}>
                            <div className="flex justify-between filter-wrapper-container">
                                <Space
                                    size={16}
                                    direction="horizontal"
                                    wrap={true}
                                    className="mb-8"
                                >
                                    <DateRangeFilter
                                        dateRange={filter?.dateRange}
                                        setDateRange={onSetDateRange}
                                    />
                                </Space>

                                <DownloadPrint
                                    title={translate('payouts.payout', { ns: 'common' })}
                                    dateRange={filter?.dateRange}
                                    reportType={ExportTypeEnum.PAYOUTS}
                                    timeZone={timeZone}
                                    isExportExcel
                                />
                            </div>
                        </Col>
                        <Col xs={24}>
                            <section className="py-8 item-summary">
                                <h3 className="mb-4">{translate('payouts.summary')}</h3>
                                <Row gutter={[16, 16]}>
                                    <Col
                                        xs={24}
                                        md={
                                            paymentAccount?.data?.capabilities
                                                ?.totalPendingPayoutAmount || 0 > 0
                                                ? 12
                                                : 24
                                        }
                                    >
                                        <Card className="min-h-full">
                                            <Statistic
                                                title={translate('payouts.total_payout_amount')}
                                                value={convertPrice(
                                                    _payoutData?.data?.totalPayout || 0,
                                                )}
                                                precision={0}
                                            />
                                        </Card>
                                    </Col>
                                    {paymentAccount?.data?.capabilities?.totalPendingPayoutAmount &&
                                    paymentAccount.data.capabilities.totalPendingPayoutAmount >
                                        0 ? (
                                        <Col xs={24} md={12}>
                                            <Card className="min-h-full">
                                                <Statistic
                                                    title={translate(
                                                        'payouts.total_pending_payout_amount',
                                                    )}
                                                    value={convertPrice(
                                                        paymentAccount.data.capabilities
                                                            .totalPendingPayoutAmount,
                                                    )}
                                                    precision={0}
                                                />
                                            </Card>
                                        </Col>
                                    ) : null}
                                </Row>
                            </section>
                        </Col>
                    </Row>

                    <Row className="payouts-table-container">
                        <Col span={24} className="flex flex-col">
                            <Table
                                className="payouts-table"
                                columns={columns}
                                dataSource={_payoutData?.data?.payouts || []}
                                loading={isFetching}
                                pagination={false}
                                scroll={{ x: '700px', y: '100%' }}
                                onChange={onChangeTable}
                            />
                        </Col>
                        <Col span={24}>
                            <div className="pagination-container pt-3 pb-4 px-6">
                                <CustomPagination
                                    pageSize={pageSize}
                                    total={_payoutData?.total}
                                    current={pageIndex}
                                    onChange={(value: number, size: number) => {
                                        setPageIndex(value);
                                        setPageSize(size);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            </section>
            {isOpenOtp ? (
                <OtpConfirm
                    title={translate('otp.withdraw', { ns: 'common' })}
                    visible={isOpenOtp}
                    isSendingOtp={isSendingOtp}
                    confirmData={{
                        email: currentUser?.email ?? '',
                        resourceName: OTP_RESOURCE_NAME.PAYOUT,
                        id: currentUser?.id ?? '',
                    }}
                    setVisible={setIsOpenOtp}
                    onClose={(isVerified: boolean) => {
                        if (!isVerified) {
                            return;
                        }
                        handleAfterVerifyOtp();
                    }}
                    onResend={() => handleSendOtp()}
                />
            ) : null}
        </Spin>
    );
};
