import { DownloadPrint } from '@components/internal-crm/contact/download-pdf';
import { ExportTypeEnum } from '@enums/export-file.enum';
import { IReport } from '@interfaces/report';
import { useList } from '@refinedev/core';
import { formatPrice, formatPriceBaseOnCurrency, uppercaseAll } from '@utils/resource';
import {
    Card,
    Col,
    Divider,
    notification,
    Row,
    Spin,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useFilterContext } from 'contexts/filter.context';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const SettlementDetail: React.FC<{
    settlementId: string;
    settlementDetail: IReport.ISettlementDetailReportDto;
}> = ({ settlementId, settlementDetail }) => {
    const { t } = useTranslation(['common']);

    const timeZone = moment.tz.guess();

    const formatDate = (dateValue: string) => dayjs(dateValue).format('M/D/YYYY h:mm A');
    const [offset, setOffset] = useState(1);
    const [limit] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [entriesData, setEntriesData] = useState<IReport.IEntries[]>([]);
    const [isEntriesTabActive, setIsEntriesTabActive] = useState(false);
    const [activeKey, setActiveKey] = useState('1');

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const {
        data: fetchedData,
        isFetching: isLoadingEntries,
        refetch: getSettlementsEntriesReport,
    } = useList<IReport.IEntries>({
        resource: 'v1/report/settlements/entries',
        queryOptions: { enabled: false },
        config: {
            hasPagination: false,
            filters: [
                { field: 'settlementId', operator: 'eq', value: settlementId },
                { field: 'skip', operator: 'eq', value: offset },
                { field: 'take', operator: 'eq', value: limit },
            ],
        },
    });

    const handleChangeTabs = (activeKey: string) => {
        if (activeKey === '2') {
            setIsEntriesTabActive(true);
        }
        setActiveKey(activeKey);
    };

    useEffect(() => {
        if (isEntriesTabActive && isFirstLoad) {
            setLoading(true);
            loadMoreData();
            setIsFirstLoad(false);
            setLoading(false);
        }
    }, [isEntriesTabActive]);

    useEffect(() => {
        if (fetchedData?.data) {
            setEntriesData((prev) => [...prev, ...fetchedData.data]);

            if (fetchedData.data.length < limit) {
                setHasMore(false);
            } else {
                setHasMore(true);
                setOffset((prevOffset) => prevOffset + limit); // Increase offset
            }
            setLoading(false);
        }
    }, [fetchedData]);

    const loadMoreData = () => {
        if (hasMore && !isLoadingEntries) {
            getSettlementsEntriesReport(); // Fetch the next batch of data
        }
    };

    const renderInlineCounter = (text: string, count: number) => (
        <span>
            {text} <span className={styles.countSettlement}>{count}</span>
        </span>
    );

    const TooltipWithCopy: React.FC<{ text: string; label: string }> = ({ text, label }) => {
        const { t } = useTranslation(['common']);

        return (
            <Tooltip
                title={
                    <div className={styles.customTooltip}>
                        <span className={styles.textTooltip}>{text}</span>
                        <span className={styles.copyCode} onClick={() => handleCopyCode(text)}>
                            {t('settlements.click_to_copy', { ns: 'common' })}
                        </span>
                    </div>
                }
                trigger={'hover'}
                placement="right"
                overlayClassName={styles.customOverlay}
            >
                <Tag color="default">{label}</Tag>
            </Tooltip>
        );
    };

    const handleCopyCode = (code: string) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(code).then(
                () => {
                    notification.success({
                        message: '',
                        description: t('settlements.copy_clipboard_success', { ns: 'common' }),
                    });
                },
                () => {
                    notification.error({
                        message: '',
                        description: t('settlements.copy_clipboard_failed', { ns: 'common' }),
                    });
                },
            );
        }
    };

    // style state color for the table
    const stateColor = (state: string) => {
        switch (state) {
            case 'SUCCEEDED':
                return 'green';
            case 'CANCELED':
                return 'red';
            case 'PENDING':
                return 'orange';
            default:
                return 'blue';
        }
    };

    const columnsOverview = [
        {
            title: '',
            dataIndex: 'label',
            key: 'label',
            fixed: 'left' as 'left' | 'right',
            width: 150,
            children: [
                {
                    title: '',
                    dataIndex: '',
                    key: '',
                    width: 150,
                    render: () => (
                        <>
                            <span className="font-bold">
                                {t('settlements.amount', { ns: 'common' })}{' '}
                            </span>
                            <span>({t(`settlements.currency.usd`, { ns: 'common' })})</span>
                        </>
                    ),
                },
            ],
        },
        {
            title: t('settlements.transactions', { ns: 'common' }),
            children: [
                {
                    title: renderInlineCounter(
                        t('settlements.debits', { ns: 'common' }),
                        settlementDetail?.debitCount || 0,
                    ),
                    dataIndex: 'debitAmount',
                    key: 'debitAmount',
                    width: 100,
                    render: (debitAmount: number) =>
                        formatPriceBaseOnCurrency({ price: debitAmount || 0 }),
                },
                {
                    title: renderInlineCounter(
                        t('settlements.credits', { ns: 'common' }),
                        settlementDetail?.creditCount || 0,
                    ),
                    dataIndex: 'creditAmount',
                    key: 'creditAmount',
                    width: 100,
                    render: (creditAmount: number) =>
                        formatPriceBaseOnCurrency({ price: creditAmount || 0 }),
                },
                {
                    title: renderInlineCounter(
                        t('settlements.fees', { ns: 'common' }),
                        settlementDetail?.feeCount || 0,
                    ),
                    dataIndex: 'feesAmount',
                    key: 'feesAmount',
                    width: 100,
                    render: (feesAmount: number) =>
                        formatPriceBaseOnCurrency({ price: feesAmount || 0 }),
                },
                {
                    title: renderInlineCounter(
                        t('settlements.refunds', { ns: 'common' }),
                        settlementDetail?.refundsCount || 0,
                    ),
                    dataIndex: 'refundsAmount',
                    key: 'refundsAmount',
                    width: 100,
                    render: (refundsAmount: number) =>
                        formatPriceBaseOnCurrency({ price: refundsAmount || 0 }),
                },
                {
                    title: renderInlineCounter(
                        t('settlements.settled_externally', { ns: 'common' }),
                        0,
                    ),
                    dataIndex: 'settledExternally',
                    key: 'settledExternally',
                    width: 150,

                    //  develop later
                    render: (settledExternally: number) =>
                        formatPriceBaseOnCurrency({ price: settledExternally || 0 }),
                },
            ],
        },
        {
            title: t('settlements.disputes', { ns: 'common' }),
            children: [
                {
                    title: renderInlineCounter(t('settlements.debits', { ns: 'common' }), 0),
                    dataIndex: 'disputesDebits',
                    key: 'disputesDebits',
                    width: 100,
                    //  develop later
                    render: (disputesDebits: number) =>
                        formatPriceBaseOnCurrency({ price: disputesDebits || 0 }),
                },
                {
                    title: renderInlineCounter(t('settlements.credits', { ns: 'common' }), 0),
                    dataIndex: 'disputesCredits',
                    key: 'disputesCredits',
                    width: 100,
                    render: (disputesCredits: number) =>
                        formatPriceBaseOnCurrency({ price: disputesCredits || 0 }),
                },
            ],
        },
        {
            title: t('settlements.merchant_adjustments', { ns: 'common' }),
            children: [
                {
                    title: renderInlineCounter(t('settlements.debits', { ns: 'common' }), 0),
                    dataIndex: 'merchantAdjustmentsDebits',
                    key: 'merchantAdjustmentsDebits',
                    width: 100,
                    render: (merchantAdjustmentsDebits: number) =>
                        formatPriceBaseOnCurrency({ price: merchantAdjustmentsDebits || 0 }),
                },
                {
                    title: renderInlineCounter(t('settlements.credits', { ns: 'common' }), 0),
                    dataIndex: 'merchantAdjustmentsCredits',
                    key: 'merchantAdjustmentsCredits',
                    width: 100,
                    render: (merchantAdjustmentsCredits: number) =>
                        formatPriceBaseOnCurrency({ price: merchantAdjustmentsCredits || 0 }),
                },
            ],
        },
        {
            title: t('settlements.net_amount', { ns: 'common' }),
            dataIndex: 'netAmount',
            key: 'netAmount',
            width: 150,
            render: (netAmount: number) => formatPriceBaseOnCurrency({ price: netAmount || 0 }),
        },
    ];

    // Columns for the Table
    const columnsEntries = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text: string) => <TooltipWithCopy text={text} label="ID" />,
        },
        {
            title: t('settlements.trace_id', { ns: 'common' }),
            dataIndex: 'traceId',
            key: 'traceId',
            render: (traceId: string) =>
                traceId?.trim() ? <TooltipWithCopy text={traceId} label="Trace ID" /> : '-',
        },
        {
            title: t('settlements.created_on', { ns: 'common' }),
            dataIndex: 'createdOn',
            key: 'createdOn',
            sorter: (a: { createdOn: string }, b: { createdOn: string }) =>
                new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
            render: (createdOn: string) => (
                <div>
                    <div>{dayjs(createdOn).format('M/D/YYYY')}</div>
                    <div className={styles.textDate}>{dayjs(createdOn).format('h:mm A')}</div>
                </div>
            ),
        },
        {
            title: t('settlements.amount', { ns: 'common' }),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => (
                <>
                    <span className="font-bold">
                        {formatPriceBaseOnCurrency({ price: amount })}
                    </span>{' '}
                    <span>
                        {t(`settlements.currency.usd`, {
                            ns: 'common',
                        })}
                    </span>
                </>
            ),
        },
        {
            title: t('settlements.transaction_type', { ns: 'common' }),
            dataIndex: 'transactionType',
            key: 'transactionType',
            render: (transactionType: string) => <span>{convertToTitleCase(transactionType)}</span>,
        },
        {
            title: t('settlements.fee_type', { ns: 'common' }),
            dataIndex: 'feeType',
            key: 'feeType',
            render: (feeType: string) => (
                <span>{feeType?.trim() ? convertToTitleCase(feeType) : '-'}</span>
            ),
        },
        {
            title: t('settlements.buyer_name', { ns: 'common' }),
            dataIndex: 'buyerName',
            key: 'buyerName',
            render: (buyerName: string) => <span>{buyerName?.trim() ? buyerName : '-'}</span>,
        },
    ];

    const formatDateTimeWithTimezone = (dateValue: string) => {
        return (
            <div>
                <div>{dayjs(dateValue).format('M/D/YYYY')}</div>
                <div className={styles.textDate}>{dayjs(dateValue).format('h:mm A')}</div>
            </div>
        );
    };

    const convertToTitleCase = (str: string) => {
        return _.startCase(_.toLower(str.replace(/_/g, ' ')));
    };

    const fundingColumns: ColumnsType<IReport.IFundingTransfers> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <TooltipWithCopy text={text} label="ID" />,
            width: 50,
        },
        {
            title: t('settlements.trace_id', { ns: 'common' }),
            dataIndex: 'traceId',
            key: 'traceId',
            render: (text) => <TooltipWithCopy text={text} label="Trace ID" />,
            width: 100,
        },
        {
            title: t('settlements.created_on', { ns: 'common' }),
            dataIndex: 'createdOn',
            key: 'createdOn',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
            render: (createdOn: string) => formatDateTimeWithTimezone(createdOn),
            width: 200,
        },
        {
            title: t('settlements.amount', { ns: 'common' }),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => (
                <>
                    {formatPriceBaseOnCurrency({ price: amount })}{' '}
                    <span className={`${styles.headerSettlementTitle}`}>
                        {t(`settlements.currency.usd`, { ns: 'common' })}
                    </span>
                </>
            ),
            width: 200,
        },
        {
            title: t('settlements.deposit_type.title', { ns: 'common' }),
            dataIndex: 'depositType',
            key: 'depositType',
            render: (depositType: string) => (
                <span>
                    {t(`settlements.deposit_type.${depositType.toLowerCase()}`, {
                        ns: 'common',
                    })}
                </span>
            ),
            width: 150,
        },
        {
            title: t('settlements.level', { ns: 'common' }),
            dataIndex: 'level',
            key: 'level',
            render: (level: string) => <span>{convertToTitleCase(level)}</span>,
        },
        {
            title: t('settlements.state.title', { ns: 'common' }),
            dataIndex: 'state',
            key: 'state',
            render: (state: string) => (
                <Tag color={stateColor(state)}>
                    {t(`settlements.state.${state.toLowerCase()}`, { ns: 'common' })}
                </Tag>
            ),
        },
        {
            title: t('settlements.payment_instrument', { ns: 'common' }),
            dataIndex: 'paymentInstrument',
            key: 'paymentInstrument',
            render: (paymentInstrument: string) => <span>{paymentInstrument}</span>,
        },
    ];

    return (
        <Card bordered={false} className={styles.container}>
            {/* Header Section */}
            <Row gutter={[16, 16]} className={styles.header}>
                <Col xs={24} sm={12} md={8} lg={4}>
                    <Title
                        level={4}
                        style={{ marginBottom: 0 }}
                        className="flex items-center gap-3"
                    >
                        {t('settlements.title', { ns: 'common' })}{' '}
                        <TooltipWithCopy text={settlementDetail?.id as string} label="ID" />
                    </Title>
                    <Text className={styles.textLarge}>
                        {formatPriceBaseOnCurrency({ price: settlementDetail?.totalAmount || 0 })}{' '}
                        {t(`settlements.currency.usd`, { ns: 'common' })}
                    </Text>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Text className={styles.headerSettlementTitle}>
                        {uppercaseAll(t(`settlements.fee_amount`, { ns: 'common' }))}
                    </Text>
                    <div>
                        <Text strong>
                            (
                            {formatPriceBaseOnCurrency({
                                price: settlementDetail?.feesAmount || 0,
                            })}
                            ) {t(`settlements.currency.usd`, { ns: 'common' })}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Text className={styles.headerSettlementTitle}>
                        {uppercaseAll(t(`settlements.net_amount`, { ns: 'common' }))}
                    </Text>
                    <div>
                        <Text strong>
                            {formatPriceBaseOnCurrency({ price: settlementDetail?.netAmount || 0 })}{' '}
                            ({t(`settlements.currency.usd`, { ns: 'common' })})
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Text className={`${styles.headerSettlementTitle} ${styles.stateContainer}`}>
                        {uppercaseAll(t(`settlements.state.title`, { ns: 'common' }))}
                    </Text>
                    <div>
                        <div className={styles.stateValue}>
                            <div
                                className={`${styles.stateIcon} ${
                                    settlementDetail?.state.toLowerCase() === 'approved'
                                        ? styles.stateApproved
                                        : styles.stateAccruing
                                }`}
                            ></div>
                            {t(`settlements.state.${settlementDetail?.state.toLowerCase()}`, {
                                ns: 'common',
                            }) || 'Approved'}
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <Text className={styles.headerSettlementTitle}>
                        {uppercaseAll(t(`settlements.created_on`, { ns: 'common' }))}
                    </Text>
                    <div>
                        <Text strong>{formatDate(settlementDetail?.createdOn as string)}</Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4}>
                    <Text className={styles.headerSettlementTitle}>
                        {uppercaseAll(
                            t(`settlements.payment_settlement_schedule`, { ns: 'common' }),
                        )}
                    </Text>
                    <div>
                        <Text strong>{settlementDetail?.payoutSettlementSchedule || 'Daily'}</Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4}>
                    <Text className={styles.headerSettlementTitle}>
                        {uppercaseAll(t(`settlements.payout_type`, { ns: 'common' }))}
                    </Text>
                    <div>
                        <Text strong>{settlementDetail?.payoutType || 'Net'}</Text>
                    </div>
                </Col>
            </Row>
            <Divider />

            <Tabs
                defaultActiveKey="1"
                style={{ marginTop: 20 }}
                onChange={handleChangeTabs}
                className={styles.customTabs}
                tabBarExtraContent={{
                    right: (
                        <>
                            {activeKey === '2' && (
                                <DownloadPrint
                                    title={t('settlements.entries', { ns: 'common' })}
                                    reportType={ExportTypeEnum.SETTLEMENTS_ENTRIES}
                                    timeZone={timeZone}
                                    settlementId={settlementId}
                                    isExportExcel
                                />
                            )}
                        </>
                    ),
                }}
            >
                <TabPane tab={t('settlements.general_info', { ns: 'common' })} key="1">
                    <Title level={3}>{t('settlements.details', { ns: 'common' })}</Title>
                    <Row className={styles.detailsRow} gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={6}>
                            <Text>{t('settlements.entry_accrual_start', { ns: 'common' })}</Text>
                            <div>
                                <Text strong>
                                    {formatDate(settlementDetail?.entryAccrualStart as string)}
                                </Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Text>{t('settlements.entry_accrual_end', { ns: 'common' })}</Text>
                            <div>
                                <Text strong>
                                    {formatDate(settlementDetail?.entryAccrualEnd as string)}
                                </Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Text>{t('settlements.auto_close', { ns: 'common' })}</Text>
                            <div>
                                <Text strong>
                                    {formatDate(settlementDetail?.autoClose as string)}
                                </Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Text>{t('settlements.processor', { ns: 'common' })}</Text>
                            <div>
                                <Text strong>{settlementDetail?.processor || 'DUMMY_V1'}</Text>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Typography.Title level={3}>
                        {t('settlements.merchant_payout_profile', { ns: 'common' })}
                    </Typography.Title>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Text>{t('settlements.payout_type', { ns: 'common' })}</Text>
                            <div>
                                <Text strong>{'Net'}</Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Text>
                                {t('settlements.payment_settlement_schedule', {
                                    ns: 'common',
                                })}
                            </Text>
                            <div>
                                <Text strong>
                                    {settlementDetail?.payoutSettlementSchedule || 'Daily'}
                                </Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Text>
                                {t('settlements.funding_transfer_speed', {
                                    ns: 'common',
                                })}
                            </Text>
                            <div>
                                <Text strong>{settlementDetail?.fundingTransferSpeed || '-'}</Text>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <div className={styles.tableContainer}>
                        <div
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                marginBottom: '1rem',
                            }}
                        >
                            <Typography.Title level={3}>
                                {t('settlements.overview', {
                                    ns: 'common',
                                })}
                            </Typography.Title>
                        </div>
                        <Table
                            columns={columnsOverview}
                            dataSource={[settlementDetail || {}]}
                            bordered
                            pagination={false}
                            scroll={{ x: '1800px', y: 400 }}
                            rowKey={() => uuidv4()}
                            className={styles.tableSettlement}
                        />
                    </div>

                    {settlementDetail?.fundingTransfers?.length ? (
                        <div className={styles.tableContainer}>
                            <div
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    marginBottom: '1rem',
                                }}
                            >
                                <Typography.Title level={3}>
                                    {t('settlements.funding_transfer', {
                                        ns: 'common',
                                    })}
                                </Typography.Title>
                            </div>
                            <Table
                                columns={fundingColumns}
                                dataSource={settlementDetail.fundingTransfers}
                                pagination={false}
                                scroll={{ x: '1024px', y: 400 }}
                                bordered
                                className={styles.tableSettlement}
                            />
                        </div>
                    ) : null}
                </TabPane>
                <TabPane tab={t('settlements.entries', { ns: 'common' })} key="2">
                    <div className={styles.tableWrapper} id="scrollable">
                        {loading ? (
                            <div className={styles.loadingContainer}>
                                <Spin />
                            </div>
                        ) : (
                            <>
                                <InfiniteScroll
                                    dataLength={entriesData.length}
                                    next={loadMoreData}
                                    hasMore={hasMore}
                                    loader={<></>}
                                    scrollableTarget="scrollable"
                                >
                                    <Table
                                        columns={columnsEntries}
                                        dataSource={entriesData}
                                        pagination={false}
                                        loading={isLoadingEntries}
                                        bordered
                                        // scroll={{ x: '1024px', y: 400 }}
                                        rowKey={() => uuidv4()}
                                        className={styles.tableSettlement}
                                    />
                                </InfiniteScroll>
                            </>
                        )}
                    </div>
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default SettlementDetail;
