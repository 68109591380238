import './styles.scss';

import { Chip } from '@components/modules/chip';
import { CustomPagination } from '@components/modules/pagination';
import { StripeStatus } from '@enums/stripe-status.enum';
import { IOrder } from '@interfaces/order';
import { useCustomMutation } from '@refinedev/core';
import { formatDate } from '@utils/date';
import {
    assignLayoutTable,
    defaultStyle,
    footer,
    header,
    headerBody,
    renderTitleTable,
    styles,
} from '@utils/pdf';
import { convertPrice } from '@utils/resource';
import { Button, Table } from 'antd';
import { getEnvConfig } from 'getEnvConfig';
import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { renderSubscriptionSummary, renderSubscriptionTable } from './print-pdf-subscription';

type PaymentTabProps = {
    current: number;
    pageSize: number;
    cols: any;
    tableResult: any;
    setCurrent: (v: number) => void;
    setNextPaymentIntentId: (value: string) => void;
    setPrevPaymentIntentId: (value: string) => void;
    total?: number;
    paymentInvoiceLoading: boolean;
};

export const ContactPaymentTabContent: React.FC<PaymentTabProps> = ({
    cols,
    current,
    tableResult,
    setCurrent,
    setNextPaymentIntentId,
    setPrevPaymentIntentId,
    total,
    paymentInvoiceLoading,
}) => {
    const { t } = useTranslation(['quote', 'common']);

    const apiUrl = getEnvConfig.PORTAL_URL;

    const renderStatus = (code: string) => {
        const label = t(`stripe.${code}`, {
            ns: 'common',
        });
        let type = '';

        switch (code) {
            //Paid
            case StripeStatus.SUCCEEDED:
                type = 'success';
                break;
            //default
            default:
                type = 'disable';
                break;
        }

        return <Chip {...{ label, type }} />;
    };

    const { mutate } = useCustomMutation();

    const getSubscriptionDetail = async (id: string) => {
        if (!id) {
            return;
        }
        mutate(
            {
                url: `${apiUrl}/v1/contacts/${id}/subscription-detail`,
                method: 'post',
                values: {},
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (data, _, __) => {
                    let summaryData: Content[] = [];
                    const tableData: Content[] = [];

                    if (data) {
                        summaryData = renderSubscriptionSummary(
                            data?.data,
                            (rs: string, obj: any) => t(rs, obj),
                        );
                    }
                    if (data) {
                        const title = renderTitleTable(
                            t('quotes.heading.itemDetails', { ns: 'quote' }),
                        );

                        const resultMapTable = renderSubscriptionTable(
                            data?.data,
                            (rs: string, obj: any) => t(rs, obj),
                        );
                        tableData.push(title, resultMapTable);
                    }

                    const newContent: Content[] = [];

                    const columnData: any = headerBody(
                        (rs: string, obj: any) => t(rs, obj),
                        undefined,
                        data?.data?.date,
                        data?.data?.status,
                    );
                    if (summaryData.length) {
                        summaryData.forEach((item: any) => {
                            columnData.push(item);
                        });
                    }
                    newContent.push(columnData);
                    if (tableData.length) {
                        tableData.forEach((item: any) => {
                            assignLayoutTable(item);
                            newContent.push(item);
                        });
                    }

                    const objDefinition: TDocumentDefinitions = {
                        pageSize: 'A4',
                        pageMargins: [40, 80, 40, 80],
                        content: newContent,
                        styles: styles(),
                        header: header(
                            `${t('subscriptions.billing_of_invoice', { ns: 'common' })} #${
                                data?.data.invoice.incrementId
                            }`,
                            '',
                        ),
                        defaultStyle: defaultStyle(),
                        footer: (currentPage: number) => footer(currentPage),
                    };

                    const pdfDocGenerator = pdfMake.createPdf(objDefinition);
                    pdfDocGenerator.download(
                        `${t('subscriptions.billing_of', { ns: 'common' })} ${
                            data.data.contactName
                        }.pdf`,
                    );
                },
            },
        );
    };

    return (
        <div className="overflow-hidden">
            <div className="list-content table-wrapper">
                <Table
                    rowKey="id"
                    dataSource={cols}
                    pagination={false}
                    scroll={{ x: '1200px', y: '100%' }}
                    loading={paymentInvoiceLoading}
                >
                    <Table.Column
                        width={120}
                        title={<>{t('quotes.table.date')}</>}
                        dataIndex="createdAt"
                        key="createdAt"
                        render={(text, _, __) => (
                            <p className="table-tbody-text">{formatDate(text)}</p>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.card')}</>}
                        dataIndex="card"
                        key="card"
                        width={150}
                        render={(_, record: any, __) => (
                            <div className="flex justify-between items-center ">
                                <div className="flex justify-start items-center">
                                    {record?.card?.last4 ? (
                                        <>
                                            <div className="card-img ml-auto">
                                                <img
                                                    src={`/images/payments/${record?.card?.brand?.toLowerCase()}.svg`}
                                                />
                                            </div>
                                            <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                                *** ****
                                                {record.card.last4}
                                            </p>
                                        </>
                                    ) : (
                                        <div className="card-img mx-auto"> --</div>
                                    )}
                                </div>
                            </div>
                        )}
                    />
                    <Table.Column
                        title={<>{t('contact.no.', { ns: 'common' })}</>}
                        dataIndex="incrementId"
                        key="incrementId"
                        width={100}
                        render={(text, record: any, _) => (
                            <Link
                                to={`/wl/invoices/show/${record.invoiceId}`}
                                className="table-tbody-text"
                            >
                                <u> # {text}</u>
                            </Link>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.amount')}</>}
                        dataIndex="amount"
                        key="amount"
                        width={120}
                        render={(text, _: IOrder.OrderList, __) => (
                            <p className="table-tbody-text">{convertPrice(text)}</p>
                        )}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.status')}</>}
                        dataIndex="status"
                        key="status"
                        width={160}
                        render={(text, record: any, _) => {
                            return (
                                <p className={`table-tbody-text status-${text}`}>
                                    {text ? renderStatus(text) : null}
                                </p>
                            );
                        }}
                    />
                    <Table.Column
                        title={<>{t('quotes.table.description')}</>}
                        dataIndex="description"
                        key="description"
                        width={120}
                        render={(text, _, index) => (
                            <p className={`table-tbody-text status-${text}`}>{text || '-'}</p>
                        )}
                    />
                    <Table.Column
                        title={<></>}
                        dataIndex="action"
                        key="action"
                        width={70}
                        render={(_, record: any, __) => {
                            return (
                                <div
                                    className={`flex justify-between items-center md:ml-4 export-report`}
                                >
                                    <Button
                                        type="link"
                                        onClick={() => getSubscriptionDetail(record.chargeId)}
                                        icon={<img src="/images/icons/download.svg" />}
                                        className="mr-2"
                                    />
                                </div>
                            );
                        }}
                    />
                </Table>
            </div>

            <div className="pagination-container pt-3 pb-4 px-6 contact-payment-list">
                <CustomPagination
                    pageSize={5}
                    current={current}
                    onChange={(value: any, size: any) => {
                        if (current < value) {
                            setPrevPaymentIntentId('');
                            setNextPaymentIntentId(tableResult?.data.nextPaymentIntentId);
                        } else if (current > value) {
                            setNextPaymentIntentId('');
                            setPrevPaymentIntentId(tableResult?.data.prevPaymentIntentId);
                        }
                        setCurrent(value);
                    }}
                    showSizeChanger={false}
                    showPrevNextJumpers={false}
                    total={total}
                />
            </div>
        </div>
    );
};
