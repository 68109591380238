import './styles.scss';

import { WLPayoutsPage } from './wl-payouts';

export const PayoutLayout: React.FC = () => {
    return (
        <section className="account-container w-full h-full">
            <WLPayoutsPage />
        </section>
    );
};
