export const formatPhoneNumber = (phoneNumber?: string): string => {
    if (!phoneNumber) {
        return '';
    }

    const numberOnly = unmaskPhoneNumber(phoneNumber);

    if (numberOnly.length === 10) {
        return `(${numberOnly.slice(0, 3)})-${numberOnly.slice(3, 6)}-${numberOnly.slice(6)}`;
    }

    return phoneNumber;
};

export const unmaskPhoneNumber = (phoneNumber?: string): string => {
    if (!phoneNumber) {
        return '';
    }
    const numberOnly = phoneNumber.replace(/[^0-9]/g, '');
    return numberOnly;
};
