import './styles.scss';

import { FormOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { Payment } from '@interfaces/payment';
import { usePermissions } from '@refinedev/core';
import { Dropdown, Menu, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

type InvoiceTabProps = {
    cols: any;
    setPaymentDefault: (id: string) => void;
};

export const ContactPaymentMethodList: React.FC<InvoiceTabProps> = ({
    cols,
    setPaymentDefault,
}) => {
    const { t } = useTranslation(['common']);
    const { data: permissions } = usePermissions<string[]>();

    return (
        <div className="overflow-scroll payment-method-contacts">
            <div className="list-content table-wrapper ">
                <Table
                    rowKey="id"
                    showHeader={false}
                    dataSource={cols}
                    pagination={false}
                    scroll={{ x: '100%', y: '100%' }}
                >
                    <Table.Column
                        width={150}
                        dataIndex="brand"
                        key="brand"
                        sorter
                        render={(_, record: Payment.IPaymentMethod, __) => (
                            <div>
                                <div className="flex justify-between items-center ">
                                    <div className="flex justify-start items-center">
                                        <div className="card-img ml-auto">
                                            <img
                                                src={`/images/payments/${record?.brand?.toLowerCase()}.svg`}
                                            />
                                        </div>
                                        <p className="table-tbody-text ml-2 item-name pd-0 w-52">
                                            *** *** {record.last4}
                                        </p>
                                    </div>
                                </div>
                                <p className="table-tbody-text">
                                    {t('billing.expires', 'Expires')} {record.expMonth}/
                                    {record.expYear}
                                </p>
                            </div>
                        )}
                    />

                    <Table.Column
                        dataIndex="isDefault"
                        key="isDefault"
                        render={(isDefault, _: Payment.IPaymentMethod, __) => (
                            <p className="table-tbody-text">
                                {isDefault ? (
                                    <Tag>{t('contact.in_use', { ns: 'common' })}</Tag>
                                ) : null}
                            </p>
                        )}
                    />
                    <Table.Column
                        dataIndex="id"
                        key="action"
                        render={(_, record: Payment.IPaymentMethod, __) => (
                            <div className="flex justify-end items-center">
                                <Dropdown
                                    overlay={
                                        <Menu mode="vertical">
                                            {!record.isDefault &&
                                            (permissions || []).includes(
                                                PERMISSIONS.BILLING_CREATE,
                                            ) ? (
                                                <Menu.Item
                                                    key="1"
                                                    style={{
                                                        fontWeight: 500,
                                                    }}
                                                    icon={
                                                        <FormOutlined
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        setPaymentDefault(record?.id as string)
                                                    }
                                                >
                                                    {t('Set default')}
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item
                                                    key="1"
                                                    style={{
                                                        fontWeight: 500,
                                                    }}
                                                    icon={
                                                        <FormOutlined
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    }
                                                    disabled
                                                >
                                                    {t('Set default')}
                                                </Menu.Item>
                                            )}
                                        </Menu>
                                    }
                                    placement="bottomRight"
                                    arrow
                                    overlayClassName="contact-dropdown-container"
                                    trigger={['click']}
                                >
                                    <img
                                        src="/images/icons/dots-vertical.svg"
                                        alt="more"
                                        className="cursor-pointer"
                                    />
                                </Dropdown>
                            </div>
                        )}
                    />
                </Table>
            </div>
        </div>
    );
};
