import { NReport } from '@interfaces/components/report';
import { formatDate, uppercaseAll } from '@utils/resource';
import { Col, Row, Typography } from 'antd';
import { formatPriceBaseOnCurrency } from 'common/functions/format-price';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { styleCardBrand, styleStatusState } from '../..';
import TooltipWithCopy from '../../tooltip-with-copy';
import styles from '../styles.module.scss';

type IDisputeDetailProps = {
    disputeDetail: NReport.IDisputeDetailResponseDto;
};
const { Title, Text } = Typography;

const HeaderSectionDispute = ({ disputeDetail }: IDisputeDetailProps) => {
    const { t } = useTranslation(['common']);
    return (
        <Row gutter={[16, 16]} className={styles.headerDispute}>
            <Col xs={24} md={12} lg={6}>
                <Title
                    level={4}
                    className="flex items-center gap-3 cursor-pointer"
                    style={{ marginBottom: 0 }}
                >
                    {t('disputes.title', { ns: 'common' })}
                    <TooltipWithCopy text={disputeDetail?.id as string} label="ID" />
                </Title>
                <Text className={styles.textLarge}>
                    {formatPriceBaseOnCurrency(disputeDetail?.amount || 0)}{' '}
                    {t(`disputes.currency.usd`, { ns: 'common' })}
                </Text>
            </Col>
            {/* Additional Header Columns */}
            <Col xs={12} md={8} lg={3} className={styles.disputeFlexCol}>
                <Text className={styles.headerDisputeTitle}>
                    {uppercaseAll(t(`disputes.state`, { ns: 'common' }))}
                </Text>
                <div className="flex items-center">
                    <span className="pr-1">{styleStatusState(disputeDetail?.state)}</span>
                    <Text strong>{disputeDetail.state}</Text>
                </div>
            </Col>
            <Col xs={12} md={8} lg={3} className={styles.disputeFlexCol}>
                <Text className={styles.headerDisputeTitle}>
                    {uppercaseAll(t(`disputes.respond_by`, { ns: 'common' }))}
                </Text>
                <Text strong>{dayjs(disputeDetail.respondBy).format('M/D/YYYY')}</Text>
            </Col>
            <Col xs={12} md={8} lg={4} className={styles.disputeFlexCol}>
                <Text className={styles.headerDisputeTitle}>
                    {uppercaseAll(t(`disputes.created_at`, { ns: 'common' }))}
                </Text>
                <Text strong>{formatDate(disputeDetail?.createdAt)}</Text>
            </Col>
            <Col xs={12} md={8} lg={4} className={styles.disputeFlexCol}>
                <Text className={styles.headerDisputeTitle}>
                    {uppercaseAll(t(`disputes.original_payment`, { ns: 'common' }))}
                </Text>
                <div className="flex flex-row items-center">
                    <span className="pr-2">
                        {styleCardBrand(disputeDetail?.paymentInstrument?.brand ?? '-')}
                    </span>
                    <Text strong>
                        {formatPriceBaseOnCurrency(disputeDetail?.amount || 0)}{' '}
                        {t(`disputes.currency.usd`, { ns: 'common' })}
                    </Text>
                </div>
            </Col>
        </Row>
    );
};

export default HeaderSectionDispute;
