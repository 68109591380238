import { NReport } from '@interfaces/components/report';
import { formatPhoneNumber } from '@utils/format-phoneNumber';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import TooltipWithCopy from '../../tooltip-with-copy';
import styles from '../styles.module.scss';

interface IBuyerDetails {
    title: string;
    buyerDetails: NReport.IBuyerDetails;
}

const { Text } = Typography;

const BuyerDetails = ({ buyerDetails, title }: IBuyerDetails) => {
    const { t } = useTranslation(['common']);
    return (
        <>
            <div className={styles.headerColCustom}>
                <Typography.Title level={3}>
                    <Typography.Title level={3}>{title}</Typography.Title>
                </Typography.Title>
                <div className="m-2">
                    <TooltipWithCopy text={buyerDetails.id} label="ID" />
                </div>
            </div>

            <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.name', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {buyerDetails?.firstName && buyerDetails?.lastName
                                ? `${buyerDetails.firstName} ${buyerDetails.lastName}`
                                : '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.business_name', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {buyerDetails?.businessName ?? '-'}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.email', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {buyerDetails?.email || '-'}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.phone', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatPhoneNumber(buyerDetails?.phone) || '-'}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={7}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.billing_address', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {buyerDetails?.billingAddress?.line1 || '-'}
                        </Text>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default BuyerDetails;
