import { DownOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { DisputeResponseStateEnum } from '@enums/disputes.enum';
import { getLocalImageUrl } from '@utils/resource';
import {
    Alert,
    Button,
    Card,
    Collapse,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
    Upload,
    UploadFile,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { UploadProps } from 'antd/lib/upload';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TooltipWithCopy from '../../tooltip-with-copy';
import { EvidenceFile } from '..';
import styles from '../styles.module.scss';

const { Text, Link } = Typography;
const { Panel } = Collapse;

interface DisputeResponseSectionProps {
    responseState: string;
    daysRemaining: number;
    uploadFiles: EvidenceFile[];
    fileListState: UploadFile<EvidenceFile>[];
    handleFileChange: UploadProps['onChange'];
    handleDownload: (id: string, fileType: string, fileName: string) => void;
    submitEvidence: () => void;
    note: string;
    setNote: (value: string) => void;
    showModalAcceptLiability: () => void;
    getResponseState: (state: string) => string;
}

const DisputeResponseSection: React.FC<DisputeResponseSectionProps> = ({
    responseState,
    daysRemaining,
    uploadFiles,
    fileListState,
    handleFileChange,
    handleDownload,
    submitEvidence,
    note,
    setNote,
    showModalAcceptLiability,
    getResponseState,
}) => {
    const { t } = useTranslation(['common']);

    const MAX_FILES = 8;

    const columnsUpload = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text: string) => (
                <span className="cursor-pointer">
                    <TooltipWithCopy text={text} label="ID" />
                </span>
            ),
        },
        {
            title: `${t('disputes.file_name', { ns: 'common' })}`,
            dataIndex: 'fileName',
            key: 'fileName',
            render: (fileName: string, record: any) => (
                <Link onClick={() => handleDownload(record?.id, record?.fileType, fileName)}>
                    {fileName}
                </Link>
            ),
        },
        {
            title: `${t('disputes.file_status', { ns: 'common' })}`,
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag className="cursor-pointer" color="orange">
                    <Tooltip title="The file is currently pending review">
                        <InfoCircleOutlined /> {status}
                    </Tooltip>
                </Tag>
            ),
        },
        {
            title: `${t('disputes.file_type', { ns: 'common' })}`,
            dataIndex: 'fileType',
            key: 'fileType',
        },
        {
            title: `${t('disputes.upload_on', { ns: 'common' })}`,
            dataIndex: 'uploadedOn',
            key: 'uploadedOn',
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            {/* Added Spin wrapper */}
            <Card className={styles.responseContainer} bordered={false}>
                <div className={styles.disputeFlexRow}>
                    <div className={styles.disputeFlexCol}>
                        <Text className={styles.responseHeader}>
                            {getResponseState(responseState)}
                        </Text>
                        <Text className={styles.responseSubHeader}>
                            {t('disputes.fraud', { ns: 'common' })}
                        </Text>
                    </div>
                    {responseState === DisputeResponseStateEnum.NEEDS_RESPONSE && (
                        <Text className={`${styles.responseTime} flex items-center`}>
                            {daysRemaining < 7 && (
                                <img
                                    src={getLocalImageUrl('assets/images/timeout.svg')}
                                    alt="edit"
                                    className="cursor-pointer ml-1"
                                />
                            )}
                            <>
                                {t('disputes.respond_within', {
                                    ns: 'common',
                                })}{' '}
                                {daysRemaining}{' '}
                                {daysRemaining > 1
                                    ? t('disputes.days', { ns: 'common' })
                                    : t('disputes.day', { ns: 'common' })}
                            </>
                        </Text>
                    )}
                </div>

                <div
                    className={
                        responseState !== DisputeResponseStateEnum.NEEDS_RESPONSE &&
                        !uploadFiles?.length
                            ? styles.uploadContainerCustom
                            : styles.uploadContainer
                    }
                >
                    {responseState === DisputeResponseStateEnum.NEEDS_RESPONSE && (
                        <>
                            <Text className={`${styles.uploadHeader} cursor-pointer`}>
                                {t('disputes.submit_evidence', {
                                    ns: 'common',
                                })}
                                <Tooltip
                                    title={
                                        <div className={styles.customTooltipLink}>
                                            <Text strong className={styles.tooltipHeader}>
                                                {t('disputes.submit_evidence_tooltip_title', {
                                                    ns: 'common',
                                                })}
                                            </Text>
                                            <Text className={styles.disputeTooltipTextGrey}>
                                                {t('disputes.submit_evidence_tooltip_content', {
                                                    ns: 'common',
                                                })}{' '}
                                                <Text className={`${styles.tooltipSubheader}`}>
                                                    {t('disputes.submit_evidence_tooltip_title', {
                                                        ns: 'common',
                                                    })}
                                                </Text>
                                                .{' '}
                                                {t(
                                                    'disputes.submit_evidence_tooltip_second_content',
                                                    {
                                                        ns: 'common',
                                                    },
                                                )}
                                            </Text>
                                            <Text
                                                className={`${styles.disputeTooltipText} ${styles.disputeTooltipTextGrey}`}
                                            >
                                                {t('disputes.submit_evidence_note', {
                                                    ns: 'common',
                                                })}
                                            </Text>
                                            <Link
                                                href="https://finix.com/docs/guides/after-the-payment/disputes/responding-disputes/"
                                                target="_blank"
                                                className={styles.disputeTooltipText}
                                            >
                                                {t('disputes.submit_evidence_button', {
                                                    ns: 'common',
                                                })}
                                            </Link>
                                        </div>
                                    }
                                    trigger="hover"
                                    placement="bottom"
                                    className="pl-1"
                                    overlayClassName={styles.customOverlayDispute}
                                >
                                    <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </Text>
                            <Text className={styles.uploadInstructions}>
                                {t('disputes.submit_evidence_des', {
                                    ns: 'common',
                                })}
                            </Text>
                            {uploadFiles?.length < MAX_FILES ? (
                                <Upload
                                    fileList={fileListState}
                                    onChange={handleFileChange}
                                    className={styles.uploadArea}
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    multiple
                                    accept=".jpg,.pdf"
                                >
                                    <UploadOutlined />
                                    <Text className={styles.uploadText}>
                                        {t('disputes.drag_drop_files', {
                                            ns: 'common',
                                        })}{' '}
                                        <span className={styles.acceptLink}>
                                            {t('disputes.click_here', {
                                                ns: 'common',
                                            })}
                                        </span>{' '}
                                        {t('disputes.to_upload', {
                                            ns: 'common',
                                        })}
                                    </Text>
                                    {' - '}
                                    <Text type="secondary">
                                        {t('disputes.upload_image_format', {
                                            ns: 'common',
                                        })}
                                    </Text>
                                </Upload>
                            ) : (
                                <div className={styles.uploadAreaDisabled}>
                                    <div className={styles.maxFilesMessage}>
                                        <InfoCircleOutlined
                                            className={styles.maxFilesMessageIcon}
                                        />
                                        <span>
                                            {t('disputes.upload_limit_reached', {
                                                ns: 'common',
                                            })}
                                        </span>
                                    </div>
                                    <Text className={styles.noteText}>
                                        {t('disputes.upload_limit_note', {
                                            ns: 'common',
                                        })}
                                    </Text>
                                </div>
                            )}
                        </>
                    )}

                    {uploadFiles?.length ? (
                        <>
                            {responseState === DisputeResponseStateEnum.ACCEPTED ? (
                                <Text className={styles.titleTable}>
                                    {t('disputes.upload_files', {
                                        ns: 'common',
                                    })}{' '}
                                </Text>
                            ) : responseState === DisputeResponseStateEnum.RESPONDED ? (
                                <Text className={styles.titleTable}>
                                    {t('disputes.submitted_files', {
                                        ns: 'common',
                                    })}{' '}
                                </Text>
                            ) : (
                                <></>
                            )}
                            <Table
                                columns={columnsUpload}
                                dataSource={uploadFiles}
                                rowKey="id"
                                pagination={false}
                                className={styles.uploadTable}
                                scroll={{ x: '1024px', y: '100%' }}
                                style={{ marginTop: 16 }}
                            />

                            {responseState === DisputeResponseStateEnum.NEEDS_RESPONSE && (
                                <>
                                    {/* Collapse for the Submit Evidence Form */}
                                    <Collapse
                                        bordered={false}
                                        style={{ marginTop: 16 }}
                                        expandIconPosition="right"
                                        className={styles.collapseContainer}
                                    >
                                        <Panel
                                            header={
                                                <Button
                                                    type="primary"
                                                    className={styles.collapseButton}
                                                >
                                                    <span className="text-xs">
                                                        {t('disputes.add_details_submit', {
                                                            ns: 'common',
                                                        })}
                                                    </span>
                                                    <DownOutlined className={styles.collapseIcon} />
                                                </Button>
                                            }
                                            key="1"
                                            showArrow={false}
                                        >
                                            <div className={styles.submitForm}>
                                                <Text strong>
                                                    {t('disputes.submit_evidence_form', {
                                                        ns: 'common',
                                                    })}
                                                </Text>
                                                <Text
                                                    type="secondary"
                                                    style={{
                                                        display: 'block',
                                                        marginBottom: 8,
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {t('disputes.summary_evidence', {
                                                        ns: 'common',
                                                    })}{' '}
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                <Text strong>
                                                                    {t('disputes.response_issuer', {
                                                                        ns: 'common',
                                                                    })}
                                                                </Text>
                                                                <Text
                                                                    className={`${styles.disputeTooltipText} ${styles.disputeTooltipTextGrey}`}
                                                                >
                                                                    {t(
                                                                        'disputes.response_issuer_content',
                                                                        {
                                                                            ns: 'common',
                                                                        },
                                                                    )}
                                                                </Text>
                                                            </div>
                                                        }
                                                        trigger="hover"
                                                        placement="bottom"
                                                        className="pl-1"
                                                        overlayClassName={
                                                            styles.customOverlayDispute
                                                        }
                                                    >
                                                        <InfoCircleOutlined
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Text>
                                                <TextArea
                                                    value={note}
                                                    onChange={(event) =>
                                                        setNote(event.target.value)
                                                    }
                                                    rows={4}
                                                    placeholder={t('disputes.summary_placeholder', {
                                                        ns: 'common',
                                                    })}
                                                    style={{ marginBottom: 16 }}
                                                />

                                                <Alert
                                                    message={t('disputes.alert_submit_evidence', {
                                                        ns: 'common',
                                                    })}
                                                    type="warning"
                                                    showIcon
                                                    style={{
                                                        backgroundColor: '#fff7e6',
                                                        border: '1px solid #ffe58f',
                                                        marginBottom: 16,
                                                        borderRadius: '4px',
                                                    }}
                                                />

                                                <Button
                                                    type="primary"
                                                    className={styles.collapseButton}
                                                    onClick={submitEvidence}
                                                >
                                                    <span className="text-xs">
                                                        {t('disputes.submit_evidence_btn', {
                                                            ns: 'common',
                                                        })}
                                                    </span>
                                                </Button>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>

                {responseState === DisputeResponseStateEnum.NEEDS_RESPONSE && (
                    <div className={styles.responseFooter}>
                        <Text>
                            {t('disputes.accept_dispute', { ns: 'common' })}{' '}
                            <Link className={styles.acceptLink} onClick={showModalAcceptLiability}>
                                {t('disputes.accept_dispute_link', {
                                    ns: 'common',
                                })}
                            </Link>
                            <Tooltip
                                title={
                                    <div>
                                        <Text strong>
                                            {t('disputes.accepting_liability', {
                                                ns: 'common',
                                            })}
                                        </Text>
                                        <Text
                                            className={`${styles.disputeTooltipText} ${styles.disputeTooltipTextGrey}`}
                                        >
                                            {t('disputes.accepting_liability_content', {
                                                ns: 'common',
                                            })}
                                        </Text>
                                        <Link
                                            href="https://finix.com/docs/guides/after-the-payment/disputes/responding-disputes/"
                                            target="_blank"
                                            className={styles.disputeTooltipText}
                                        >
                                            {t('disputes.link_accept_liability', {
                                                ns: 'common',
                                            })}
                                        </Link>
                                    </div>
                                }
                                trigger="hover"
                                placement="bottom"
                                className="pl-1"
                                overlayClassName={styles.customOverlayDispute}
                            >
                                <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </Text>
                    </div>
                )}
            </Card>
        </Row>
    );
};

export default DisputeResponseSection;
