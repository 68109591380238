const FIX_CHARACTERS = 2;
export const formatPriceBaseOnCurrency = (price: number, config: any | null = null): string => {
    const formatter = new Intl.NumberFormat(config?.localeString ?? 'en-US', {
        style: 'currency',
        currency: config?.currencyFormat ?? 'USD',
    }).format(price / 100 || 0);
    const formatVND = formatter.replaceAll('.', ',');
    return config?.symbol === 'đ' ? formatVND : formatter;
};

export const formatPrice = (price: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    return formatter.format(price / 100 || 0);
};

export const centToDollar = (price?: number | string): number =>
    price ? ((+price ? +price / 100 : price) as number) : 0;

export const dollarToCent = (price: number | string | undefined): number => {
    const floatPrice: any = price ? parseFloat(price.toString()) : 0;
    return floatPrice ? Math.round(floatPrice.toFixed(FIX_CHARACTERS) * 100) : 0;
};

export const dollarToCentNotFixed = (price: number | string | undefined): number => {
    const floatPrice: any = price ? parseFloat(price.toString()) : 0;
    return floatPrice ? floatPrice * 100 : 0;
};

export const justZeroAfterFixedPosition = (value: string): boolean => {
    const splitNumber = value.split('.');
    if (splitNumber[1]?.length) {
        const afterFixedCharacters = splitNumber[1].slice(FIX_CHARACTERS);
        if (!afterFixedCharacters?.length) return false;
        return afterFixedCharacters.split('').every((item) => +item == 0);
    }

    return false;
};

export const modifyFloatPrice = (value: string): string => {
    const splitNumber = value.split('.');
    if (splitNumber[1]?.length) {
        const decimal = splitNumber[1].slice(0, FIX_CHARACTERS);

        const natural = splitNumber[0] ?? 0;
        const mergeNumber = [natural, decimal].join('.');
        return mergeNumber;
    }

    return value;
};

export const formatCurrencyAmount = (currency: any | null, amount?: number | string): string => {
    return amount && amount != 0 ? formatPriceBaseOnCurrency(+amount, currency?.config) : '-';
};
