import './style.scss';

import { UserTypeEnum } from '@enums/user-type.enum';
import { IUser } from '@interfaces/user';
import { NUserPayment } from '@interfaces/user-payment';
import { useGetIdentity, useOne, useTranslate } from '@refinedev/core';
import { Button, Col, Image, Row } from 'antd';
import { createContext, FC, ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';

export const PayoutWLContext = createContext<string | string>(
    'useContext should be used inside ContextProvider',
);

export const PayoutWLContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const translate = useTranslate();
    const { data: userIdentity } = useGetIdentity<IUser.IUserIdentity>();

    const isAuthenticated =
        userIdentity?.userType &&
        [UserTypeEnum.WHITELABEL, UserTypeEnum.AFFILIATE_PARTNER, UserTypeEnum.TENANT].includes(
            userIdentity?.userType,
        );
    const { data: userPayment, isLoading } = useOne<NUserPayment.IUserPaymentDto>({
        resource: 'v1/user-payments',
        id: '',
        queryOptions: {
            enabled: isAuthenticated,
        },
    });

    return (
        <>
            {!isLoading && !userPayment?.data?.merchantId && isAuthenticated ? (
                <>
                    <div className="payout-notification error w-full">
                        <span className="text-base notification-text">
                            {translate('crm_internal.account_not_existed')}
                        </span>
                    </div>
                    <Row className="tenant-setting-header-row">
                        <Col
                            className="tenant-setting-header-col w-full h-full items-center justify-between"
                            span={24}
                        >
                            <h2 className="mb-2">
                                {translate('settings.update_payout_setting', 'Payout setting')}
                            </h2>
                        </Col>
                        <Button icon={false} className="create-issue-button" type="primary">
                            <Link to={`/wl/payout`} className="table-tbody-text">
                                <Image preview={false} src={'/images/icons/white-pen.svg'} />
                                <span className="ml-2">
                                    {translate('crm_internal.update_payout_setting')}
                                </span>
                            </Link>
                        </Button>
                    </Row>
                </>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export const usePayoutContext = () => {
    const context = useContext(PayoutWLContext);
    if (typeof context === 'string') {
        throw new Error(context);
    }
    return context;
};
