import './styles.scss';

import { OTP_RESOURCE_NAME } from '@constants/index.constant';
import { Otp } from '@interfaces/otp';
import { IUser } from '@interfaces/user';
import { useModal } from '@refinedev/antd';
import {
    BaseKey,
    HttpError,
    useApiUrl,
    useCreate,
    useCustom,
    useCustomMutation,
    useGetIdentity,
    useIsAuthenticated,
    useNavigation,
    useOne,
} from '@refinedev/core';
import Response from '@responses/response';
import { Button, Col, Modal, notification, Radio, RadioChangeEvent, Row, Spin, Tag } from 'antd';
import { TOKEN_KEY } from 'authProvider';
import { OtpConfirm } from 'components/modules/otp-confirm';
import { UserTypeEnum } from 'enums/user-type.enum';
import jsPDF from 'jspdf';
import { FinixPaymentModal } from 'pages/internal-crm/finix-payment-modal';
import { FinixPaymentForm } from 'pages/internal-crm/invoice/details';
import { PayoutWLContextProvider } from 'pages/payouts/context';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { maskPhone } from 'utils/string';

import { QuoteStatus } from '../../enums/quote-status.enum';
import { getEnvConfig } from '../../getEnvConfig';
import { IInvoice } from '../../interfaces/invoice';
import { FONT_INTER } from '../../utils/fonts';
import { formatDate, formatPrice } from '../../utils/resource';

export const InvoiceDetailsIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [cardId, setCardId] = useState<string | null>(null);
    const { push, goBack } = useNavigation();
    const { modalProps, show, close } = useModal();
    const { isSuccess: isAuthenticated } = useIsAuthenticated();
    const componentToPrint = useRef<any>();
    const { mutate: createToken } = useCustomMutation<Response<boolean>, HttpError, any>();
    const {
        modalProps: modalPropsAddNewCard,
        show: showModalPropsAddCard,
        close: closeModalPropsAddCard,
    } = useModal();
    const [isOpenOtp, setIsOpenOtp] = useState<boolean>(false);
    const { mutate: sendOtp, isLoading: isSendingOtp } = useCreate<Response<string>>();

    const searchQuery = window?.location?.search
        ? new URLSearchParams(window.location.search)
        : null;
    const paymentStatus = searchQuery ? searchQuery.get('redirect_status') : '';
    const invoiceQueryString = searchQuery ? searchQuery.get('invoiceFilter') : '';
    const invoiceFilter = invoiceQueryString ? qs.stringify(JSON.parse(invoiceQueryString)) : '';
    const contactId = searchQuery ? searchQuery.get('contactId') : '';
    let quoteId: string | null = null;
    quoteId = searchQuery ? searchQuery.get('quoteId') : '';
    const apiUrl = useApiUrl();

    const {
        data: invoiceData,
        refetch: invoiceRefetch,
        isLoading,
        error,
    } = useOne<IInvoice.InvoiceDetails>({
        resource: 'v1/invoices',
        id: id as BaseKey,
        queryOptions: {
            enabled: false,
        },
        errorNotification: {
            type: 'error',
            message: t('quotes.error.invoice_has_expired'),
        },
    });

    const { mutate } = useCustomMutation<any>();
    const [showFinixPaymentForm, setFinixPaymentForm] = useState<FinixPaymentForm>({
        open: false,
        secret: '',
        transactionId: '',
        invoiceId: '',
    });

    const { data: paymentMethods, refetch: paymentRefetch } = useCustom<any>({
        url: `${apiUrl}/v1/users/payment-methods`,
        method: 'get',
        queryOptions: {
            enabled: false,
        },
    });

    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();

    let token: string | null = null;
    const tokenKey = localStorage.getItem(TOKEN_KEY);
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');
    quoteId = urlParams.get('quoteId');

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);

    if (!isAuthenticated) {
        if (!token && !tokenKey) {
            push('/404');
        } else {
            invoiceRefetch();
        }
    } else {
        invoiceRefetch();
    }

    const invoice: IInvoice.InvoiceDetails | undefined = invoiceData?.data;

    const getTotalPrice = () => {
        if (invoiceData?.data?.items) {
            let total: any = 0;
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const item of invoiceData?.data?.items) {
                total += item.total;
            }

            return total;
        }
        return 0;
    };

    const handleAddNewCard = () => {
        showModalPropsAddCard();
    };

    const onChangeFinixPaymentForm = (value: FinixPaymentForm) => {
        setFinixPaymentForm(value);
    };

    const createPayment = () => {
        setLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/invoices/${id}/payment-intent`,
                method: 'post',
                values: {},
            },
            {
                onError: (error) => {
                    // An error happened!
                    setLoading(false);
                    console.error(error);
                },
                onSuccess: (data) => {
                    const result = data?.data;
                    console.log(result);
                    if (result?.data?.clientSecret) {
                        onChangeFinixPaymentForm({
                            open: true,
                            secret: result.data.clientSecret,
                            transactionId: result.data.paymentIntentId ?? '',
                            invoiceId: result.data.invoiceId ?? '',
                        });
                    }
                    setLoading(false);
                },
            },
        );
    };

    const onChangePaymentMethod = (value: RadioChangeEvent) => {
        if (!value.target.value) {
            createPayment();
        }

        setCardId(value.target.value);
    };

    const onPayClick = () => {
        if (isAuthenticated) {
            setLoading(true);
            paymentRefetch().then(() => {
                setLoading(false);
                show();
            });
        } else {
            handleSendOtp();
        }
    };

    const handleSubmit = () => {
        setLoading(true);

        mutate(
            {
                url: `${apiUrl}/v1/invoices/${id}/pay`,
                method: 'post',
                values: { cardId: cardId },
                errorNotification: false,
            },
            {
                onError: (error) => {
                    const _error = error?.response?.data?.error;
                    if (_error) {
                        const finixError = t(`crm_internal.finix.errors_code.${_error}`, {
                            ns: 'common',
                        });
                        notification.error({
                            message: finixError ?? 'Something went wrong',
                            type: 'error',
                        });
                    }
                    // An error happened!
                    console.error(error);
                    setLoading(false);
                },
                onSuccess: () => {
                    setLoading(false);
                    close();
                },
            },
        );
    };

    const handleDownload = useReactToPrint({
        content: () => componentToPrint.current,
        copyStyles: true,
        pageStyle: `
        @media all {
            .no-print {
                display: none!important;
            }
            .no-space {
                letter-spacing: 0.01px;
            }
        }
        `,
        print: async (printIframe: any) => {
            const document = printIframe.contentDocument;
            if (document) {
                const html = document.getElementsByTagName('html')[0];
                html.style.width = '900px';
                const customPrintPdf = html.querySelector('.custom-print-pdf');
                customPrintPdf.style.marginTop = '15px';
                customPrintPdf.style.paddingBottom = '12px';

                const doc = new jsPDF('p', 'px');
                doc.addFileToVFS('inter.ttf', FONT_INTER);
                doc.addFont('inter.ttf', 'inter', 'normal');
                doc.setFont('inter', 'normal');
                doc.html(html, {
                    html2canvas: {
                        scale: 0.5,
                    },
                    async callback(doc) {
                        await doc.save(`Invoice #${invoice?.incrementId}`);
                    },
                });
            }
        },
    });

    const isPlanned = (invoice?.items || []).some(
        (invoiceItem) => (invoiceItem?.percentage ?? 0) > 0,
    );

    const onCancelClick = () => {
        if (isAuthenticated) {
            if (quoteId) {
                push(`/wl/quotes/show/${quoteId}`);
            } else {
                if (invoiceFilter) {
                    push(`/wl/invoices?${invoiceFilter}`);
                } else if (contactId) {
                    goBack();
                } else {
                    push('/wl/invoices');
                }
            }
        } else {
            if (quoteId) {
                push(`/quotes/public/${quoteId}?token=${token}`);
            }
        }
    };

    const canPay = () => {
        return (
            // invoice is pending
            invoice?.status === QuoteStatus.Pending &&
            // user is a guest
            (!isAuthenticated ||
                // invoice belong to contact
                invoiceData?.data?.contactUserId === userIdentity?.id ||
                // createdBy of invoice is not tenant user or invoice userId is current user
                // wl partner and staffs can pay invoices of each other
                (!invoiceData?.data?.contactId &&
                    ((userIdentity?.userType &&
                        [UserTypeEnum.WHITELABEL, UserTypeEnum.AFFILIATE_PARTNER].includes(
                            userIdentity.userType,
                        )) ||
                        invoiceData?.data?.userId === userIdentity?.id)))
        );
    };

    const isPartnerInvoice =
        !invoice?.contactId &&
        userIdentity?.userType &&
        // user is partner type
        [UserTypeEnum.WHITELABEL, UserTypeEnum.AFFILIATE_PARTNER].includes(
            userIdentity?.userType,
        ) &&
        // invoice was created for partner
        (invoice?.userId === userIdentity?.id ||
            // staff of wl partner
            (!userIdentity?.isTenantOwner &&
                userIdentity.userType === UserTypeEnum.WHITELABEL &&
                invoice?.tenantId === userIdentity.tenantId));

    const mappingColorInvoiceStatus: { [key in QuoteStatus]?: string } = {
        [QuoteStatus.Approved]: 'green',
        [QuoteStatus.Paid]: 'green',
        [QuoteStatus.Pending]: 'blue',
        [QuoteStatus.Rejected]: 'red',
    };

    const handleSendOtp = () => {
        if (!invoice?.id) {
            notification.error({
                message: t('otp.errors.id_not_found', { ns: 'common' }),
                type: 'error',
            });
            return;
        }

        const payload: Otp.ISendOtp = {
            id: invoice.id,
            resourceName: OTP_RESOURCE_NAME.INVOICE,
            email: invoice.contactEmail,
        };
        sendOtp(
            {
                resource: 'v1/otp/code',
                values: payload,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    setIsOpenOtp(true);
                },
                onError: (error, _, __) => {
                    if (error.statusCode === 401) {
                        notification.error({
                            message: t('otp.errors.item_is_unauthorized', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }

                    if (error.message === 'can_not_create_otp_code') {
                        notification.error({
                            message: t('otp.errors.can_not_create_otp_code', { ns: 'common' }),
                            type: 'error',
                        });
                        return;
                    }
                    notification.error({
                        message: t('otp.errors.send_otp_failed', { ns: 'common' }),
                        type: 'error',
                    });
                },
            },
        );
    };

    const handleAfterVerifyOtp = () => {
        setLoading(true);
        setCardId('');
        createPayment();
    };

    useEffect(() => {
        const onSubmit = async () => {
            setLoading(true);

            form.submit(
                getEnvConfig.FINIX_ENVIRONMENT as string,
                getEnvConfig.FINIX_APPLICATION_ID as string,
                async function (err: any, res: Finix.FormResponse) {
                    const tokenData = res.data || {};
                    const token = tokenData.id;
                    if (!token) {
                        notification.error({
                            message: 'Finix generate token error',
                        });
                    }

                    createToken(
                        {
                            url: `${apiUrl}/v1/users/tokenize`,
                            method: 'post',
                            values: {
                                token: token,
                            },
                            errorNotification: false,
                        },
                        {
                            onError: (error) => {
                                setLoading(false);
                                if (error) {
                                    notification.error({
                                        message:
                                            error?.response?.data?.error ?? 'Something went wrong',
                                    });
                                    return;
                                }
                            },
                            onSuccess: (data) => {
                                setLoading(false);
                                if (!data.data?.data) {
                                    notification.error({
                                        message:
                                            t(`error.${err?.response?.data?.error}`) ??
                                            'Something went wrong',
                                    });
                                    return;
                                }

                                closeModalPropsAddCard();
                                paymentRefetch();
                            },
                        },
                    );
                },
            );
        };

        const form = window.Finix.CardTokenForm('form-bank', {
            showAddress: false,
            onSubmit,
            styles: {
                default: {
                    color: '#000',
                    fontSize: '14px',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    padding: '8px 14px',
                    border: '1px solid #d9d9d9',
                    fontFamily: `-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'`,
                },
                success: {
                    color: '#5cb85c',
                },
                error: {
                    color: '#ff4d4f',
                    border: '1px solid rgba(255,0,0, 0.3)',
                },
            },
        });
    }, [modalPropsAddNewCard.open]);

    return (
        <>
            {!isLoading && !error && (
                <section
                    className="invoice-container invoice-detail"
                    ref={(el) => (componentToPrint.current = el)}
                >
                    <div className="block-heading">
                        <div className="date-wrapper">
                            <div className="flex justify-start items-center">
                                <span>
                                    {t('quotes.heading.invoiceDetails')}: {invoice?.incrementId}
                                </span>

                                {paymentStatus === 'succeeded' ? (
                                    <Tag className="ml-4 custom-print-pdf" color="green">
                                        {t('quotes.status.' + QuoteStatus.Paid)}
                                    </Tag>
                                ) : null}

                                {invoice?.status && paymentStatus !== 'succeeded' ? (
                                    <Tag
                                        className="ml-4 custom-print-pdf"
                                        color={
                                            mappingColorInvoiceStatus[invoice.status] ?? 'default'
                                        }
                                    >
                                        {t('quotes.status.' + invoice.status)}
                                    </Tag>
                                ) : null}
                            </div>
                            <div className="date-container">
                                <span className="date-title">
                                    {t('quotes.fields.paymentDate.label')} -{' '}
                                    {formatDate(invoice?.date, 'MMM DD, YYYY')}
                                </span>
                                <span className="date-title">
                                    {t('quotes.fields.paymentDueDate.label')} -{' '}
                                    {formatDate(invoice?.dueDate, 'MMM DD, YYYY')}
                                </span>
                            </div>
                        </div>
                        <div className="ml-auto">
                            <Button
                                type="link"
                                icon={<img src="/images/icons/download.svg" />}
                                className="no-print mr-3"
                                onClick={handleDownload}
                            />
                            <ReactToPrint
                                copyStyles={true}
                                pageStyle={`
                                @page { margin: 0; }
                                @media all {
                                    .no-print {
                                        display: none!important;
                                    }
                                }
                            `}
                                trigger={() => (
                                    <Button
                                        type="link"
                                        icon={<img src="/images/icons/print.svg" />}
                                        className="no-print"
                                    />
                                )}
                                content={() => componentToPrint.current}
                            />
                        </div>
                    </div>
                    {invoice?.reason ? (
                        <div className="details-section">
                            <div className="section-header">
                                {t('invoice.change_status_reason')}
                            </div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <pre>{invoice.reason}</pre>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    {invoice?.description && (
                        <div className="details-section">
                            <div className="section-header">
                                {t('quotes.fields.description.label')}
                            </div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: invoice?.description as string,
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    {/* Invoice created by partner would not show this section information to partner */}
                    {!isPartnerInvoice ? (
                        <div className="details-section">
                            <Row gutter={20}>
                                <Col md={12} className="user-info-col">
                                    <div className="user-info">
                                        <div className="info-header">
                                            {t('quotes.heading.createdBy')}
                                        </div>
                                        <div className="name">
                                            {(invoice?.createdByUser?.firstName ?? '') +
                                                ' ' +
                                                (invoice?.createdByUser?.lastName ?? '')}
                                        </div>
                                        <div className="no-space">
                                            {invoice?.createdByUser?.email ?? ''}
                                        </div>
                                        <div className="no-space">
                                            {invoice?.createdByUser?.phone
                                                ? maskPhone(invoice?.createdByUser?.phone)
                                                : ''}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} className="user-info-col">
                                    <div className="user-info text-right">
                                        <div className="info-header">{t('quotes.heading.for')}</div>
                                        <div className="name">{invoice?.contactName}</div>
                                        <div className="no-space">{invoice?.contactEmail}</div>
                                        <div className="no-space">
                                            {invoice?.contactPhone
                                                ? maskPhone(invoice?.contactPhone)
                                                : ''}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : null}
                    <div className="details-section invoice-items-table">
                        <div className="section-header">{t('quotes.heading.itemDetails')}</div>
                        <Row className="item-list">
                            <Col span={24}>
                                <Row className="table-header">
                                    <Col span={isPlanned ? 4 : 8}>
                                        {t('quotes.fields.item.label')}
                                    </Col>
                                    <Col span={4}>{t('quotes.fields.unitPrice.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.qty.label')}</Col>
                                    <Col span={4}>{t('quotes.fields.frequency.label')}</Col>
                                    {isPlanned ? (
                                        <Col span={4}>{t('quotes.fields.percentage.label')}</Col>
                                    ) : null}
                                    <Col span={4}>{t('quotes.fields.total.label')}</Col>
                                </Row>
                                {invoice &&
                                    invoice?.items?.length > 0 &&
                                    invoice?.items?.map((item) => {
                                        return (
                                            <Row
                                                className="list-product-item"
                                                key={item?.productName + item?.id}
                                            >
                                                <Col span={isPlanned ? 4 : 8}>
                                                    {item.productName}
                                                </Col>
                                                <Col span={4}>
                                                    <span className="unit-product">
                                                        {formatPrice(
                                                            item?.unitPrice
                                                                ? item?.unitPrice +
                                                                      (item?.unitPrice *
                                                                          (item?.margin ?? 0)) /
                                                                          100
                                                                : item.total / item.qty,
                                                        )}

                                                        {userIdentity?.userType !==
                                                            UserTypeEnum.CLIENT &&
                                                        item.type === UserTypeEnum.WHITELABEL &&
                                                        item?.charge > 0 ? (
                                                            <span>
                                                                <b>
                                                                    {t(
                                                                        'quotes.fields.service_fee.label',
                                                                    )}
                                                                    :{' '}
                                                                </b>
                                                                {item.charge}%
                                                            </span>
                                                        ) : null}
                                                    </span>
                                                </Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>
                                                    {t('quotes.frequency.' + item.frequency)}
                                                </Col>
                                                {isPlanned ? (
                                                    <Col span={4}>
                                                        {item?.percentage ? item?.percentage : ''}
                                                    </Col>
                                                ) : null}

                                                <Col span={4}>{formatPrice(item.total)}</Col>
                                            </Row>
                                        );
                                    })}
                            </Col>
                        </Row>

                        <div className="sum-total flex">
                            <span>{t('quotes.heading.totalPrice')}</span>
                            <span className="ml-auto">{formatPrice(getTotalPrice())}</span>
                        </div>
                    </div>

                    <div className="invoice-items-list">
                        {invoice &&
                            invoice?.items?.length > 0 &&
                            invoice?.items?.map((item) => {
                                return (
                                    <div className="p-6" key={item?.productName + item?.id}>
                                        <Row key={`name_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.item.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{item.productName}</span>
                                            </Col>
                                        </Row>
                                        <Row key={`title_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.unitPrice.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{formatPrice(item.total / item.qty)}</span>
                                            </Col>
                                        </Row>
                                        <Row key={`qty_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.qty.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{item.qty}</span>
                                            </Col>
                                        </Row>
                                        <Row
                                            key={`frequency_${item.id}`}
                                            className="list-product-item"
                                        >
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.frequency.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>
                                                    {t('quotes.frequency.' + item.frequency)}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row key={`total_${item.id}`} className="list-product-item">
                                            <Col span={12} className="title">
                                                <span>{t('quotes.fields.total.label')}</span>
                                            </Col>
                                            <Col span={12} className="value">
                                                <span>{formatPrice(item.total)}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        <div className="sum-total flex p-6">
                            <span className="sum-total-title">
                                {t('quotes.heading.totalPrice')}
                            </span>
                            <span className="ml-auto sum-total-value">
                                {formatPrice(getTotalPrice())}
                            </span>
                        </div>
                    </div>
                    {invoice?.termsAndConditions && (
                        <div className="details-section">
                            <div className="section-header">{t('quotes.fields.tc.label')}</div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: invoice?.termsAndConditions as string,
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="details-section no-print  detail-actions">
                        <div className="flex flex-row-reverse">
                            {paymentStatus !== 'succeeded' && !cardId ? (
                                canPay() ? (
                                    <>
                                        <Button
                                            type="primary"
                                            className="ml-4"
                                            loading={loading || isSendingOtp}
                                            onClick={onPayClick}
                                        >
                                            {t('quotes.actions.pay')}
                                        </Button>
                                    </>
                                ) : null
                            ) : null}
                            <Button onClick={onCancelClick}>{t('quotes.actions.cancel')}</Button>
                        </div>
                    </div>
                </section>
            )}
            {(isLoading || !!error) && <Spin spinning={true}></Spin>}
            {isAuthenticated && (
                <Modal
                    {...modalProps}
                    title="Payment"
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <Radio.Group onChange={onChangePaymentMethod}>
                        <Row gutter={16}>
                            {paymentMethods &&
                                paymentMethods?.data?.map((paymentMethod: any) => {
                                    return (
                                        <Col span={24} md={12} key={paymentMethod.id}>
                                            <div
                                                className={`flex payment-item items-center ${
                                                    paymentMethod.id === cardId ? 'active' : ''
                                                }`}
                                            >
                                                <Radio value={paymentMethod.id}>
                                                    <div className="card-content">
                                                        <div className="card-no">
                                                            **** **** **** {paymentMethod.last4}
                                                        </div>
                                                        Ex: {paymentMethod.expMonth}/
                                                        {paymentMethod.expYear}
                                                    </div>
                                                </Radio>
                                                <div className="card-img ml-auto">
                                                    <img
                                                        src={`/images/payments/${paymentMethod.brand?.toLowerCase()}.svg`}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                        </Row>

                        <Radio value="" onClick={handleAddNewCard}>
                            <div className="new-card"> {t('quotes.actions.addNew')}</div>
                        </Radio>
                    </Radio.Group>
                    {cardId && (
                        <div className="flex">
                            <Button
                                className="mt-3 ml-auto"
                                type="primary"
                                loading={loading}
                                onClick={handleSubmit}
                            >
                                {t('quotes.actions.submit')}
                            </Button>
                        </div>
                    )}
                </Modal>
            )}

            {isOpenOtp && invoice?.id ? (
                <OtpConfirm
                    title={t('otp.pay_invoice', { ns: 'common' })}
                    visible={isOpenOtp}
                    isSendingOtp={isSendingOtp}
                    confirmData={{
                        email: invoice?.contactEmail ?? '',
                        resourceName: OTP_RESOURCE_NAME.INVOICE,
                        id: invoice?.id,
                    }}
                    setVisible={setIsOpenOtp}
                    onClose={(isVerified: boolean) => {
                        if (!isVerified) {
                            return;
                        }
                        handleAfterVerifyOtp();
                    }}
                    onResend={() => handleSendOtp()}
                />
            ) : null}

            {showFinixPaymentForm?.open && !isAuthenticated && (
                <FinixPaymentModal
                    showFinixPaymentForm={showFinixPaymentForm}
                    onChangeFinixPaymentForm={onChangeFinixPaymentForm}
                    isWL
                />
            )}

            <Modal
                {...modalPropsAddNewCard}
                title="Payment"
                maskClosable={false}
                footer={null}
                width={650}
                wrapClassName="payment-modal"
            >
                <Spin spinning={loading}>
                    <section id="form-bank"></section>
                </Spin>
            </Modal>
        </>
    );
};
