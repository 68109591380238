import './styles.scss';

import { TextEditor } from '@components/modules/editor';
import { DATE_FORMAT, SERVICE_MODULE_OPTION_ID } from '@constants/index.constant';
import { ProductFrequencyEnum } from '@enums/frequency.enum';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IInvoice } from '@interfaces/invoice';
import { BaseKey, BaseRecord, useApiUrl, useNavigation, useOne, useUpdate } from '@refinedev/core';
import { maskPhone } from '@utils/string';
import { Button, Col, DatePicker, Form, notification, Row, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import dayjs from 'dayjs';
import { PayoutContextProvider } from 'pages/internal-crm/context/payout-warning';
import { ItemsDetail } from 'pages/internal-crm/invoice/items-detail';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ItemTypeEnum } from '../../../enums/item-type.enum';
import { IContact } from '../../../interfaces/contact';

interface IEditInvoiceForm {
    date: dayjs.Dayjs | Date | string | null;
    dueDate: dayjs.Dayjs | Date | string | null;
    description: string;
    items: IEditInvoiceItemForm[];
    termsAndConditions: string;
}

interface IEditInvoiceItemForm {
    qty: number;
    margin: number;
    unitPrice: number;
    unitCost?: number;
    taxPercentage?: number;
    frequency: ProductFrequencyEnum;
    serviceId?: string;
    productId?: string;
    productName?: string;
    total?: number;
    id?: string;
    itemType?: string;
    targetId?: string;
}

export const InvoiceEditCrmIndex: React.FC = () => {
    const { t } = useTranslation(['quote', 'common']);
    const [form] = Form.useForm<IEditInvoiceForm>();
    const { id } = useParams();
    const { goBack } = useNavigation();

    const [serviceUpdated, setServiceUpdated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tenantId, setTenantId] = useState<string>();
    const [status, setStatus] = useState<QuoteStatus>(QuoteStatus.Draft);
    const { mutate } = useUpdate<BaseRecord>();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const { data: invoiceData, isLoading: loadingEditInvoice } = useOne<IInvoice.InvoiceDetails>({
        dataProviderName,
        resource: 'v1/invoices',
        id: id as BaseKey,
        errorNotification: {
            type: 'error',
            message: t('quotes.error.invoice_has_expired'),
        },
    });

    const contactId = invoiceData?.data?.contactId;

    const { data: contactDetail, refetch: refetchContactDetail } = useOne<IContact.IContact>({
        dataProviderName,
        resource: 'v1/contacts',
        id: contactId as BaseKey,
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (contactId) {
            refetchContactDetail();
        }
    }, [contactId]);

    const onChangeStatus = () => {
        setServiceUpdated(!serviceUpdated);
        onChangeMargin();
    };

    const onChangeMargin = () => {
        setServiceUpdated(!serviceUpdated);
    };

    const onFinish = (values: IEditInvoiceForm) => {
        // setIsLoading(true);
        for (const item of values.items) {
            const basePrice = +Number.parseFloat(`${item?.unitPrice}`).toFixed(2);
            item.unitPrice = +basePrice * 100;
            item.total = item?.unitPrice * item?.qty;
            item.frequency = ProductFrequencyEnum.ONE_TIME_PAYMENT;
            item.itemType =
                item.serviceId === SERVICE_MODULE_OPTION_ID
                    ? ItemTypeEnum.MODULE
                    : ItemTypeEnum.PRODUCT;
            delete item.id;
        }

        mutate(
            {
                dataProviderName,
                resource: 'v1/invoices/edit',
                id: id ?? '',
                values: values,
                successNotification: {
                    message: t('quotes.message.edit_invoice_successfully'),
                    type: 'success',
                },
            },
            {
                onError: (error, _, __) => {
                    switch (error?.message) {
                        case 'invoice_not_found':
                            notification.error({
                                message: t('quotes.error.invoice_not_found'),
                            });
                            break;
                        case 'invoice_was_not_pending':
                            notification.error({
                                message: t('quotes.error.invoice_was_not_pending'),
                            });
                            break;
                        default:
                            notification.error({
                                message: t('quotes.error.change_invoice_status_failed'),
                            });
                    }
                    const responseErr = error?.response?.data?.error;
                    if (responseErr) {
                        notification.error({
                            message: t(`quotes.error.${responseErr}`),
                            type: 'error',
                        });
                    }
                    setIsLoading(false);
                    return;
                },
                onSuccess: (_) => {
                    goBack();
                },
            },
        );
    };

    const submitForm = (status: QuoteStatus) => {
        setStatus(status);
        form.submit();
    };

    const disablePaymentDate = (current: dayjs.Dayjs): boolean => {
        return current < dayjs().startOf('day');
    };

    const disableDueDate = (current: dayjs.Dayjs): boolean => {
        const paymentDate = form.getFieldValue('date');
        return current < (paymentDate ? dayjs(paymentDate).startOf('day') : dayjs().startOf('day'));
    };

    const initEditInvoiceForm = () => {
        const _invoiceData = invoiceData?.data;
        form.setFieldsValue({
            date: _invoiceData?.date ? dayjs(_invoiceData?.date) : null,
            dueDate: _invoiceData?.dueDate ? dayjs(_invoiceData?.dueDate) : null,
            description: _invoiceData?.description ?? '',
            termsAndConditions: _invoiceData?.termsAndConditions ?? '',
            items: (_invoiceData?.items || []).map((_item) => ({
                qty: _item.qty ?? 1,
                margin: _item.margin ?? 0,
                unitPrice: _item.unitPrice,
                unitCost: _item.unitCost,
                taxPercentage: _item.taxPercentage,
                frequency: _item.frequency,
                serviceId: _item.serviceId ?? '',
                productId: _item.productId ?? '',
                productName: _item.productName ?? '',
                charge: _item.charge ?? 0,
                id: _item.id, // add id to disable select item
                targetId: _item.targetId,
            })),
        });
    };

    useEffect(() => {
        initEditInvoiceForm();
        onChangeStatus();
    }, [invoiceData?.data]);

    const handleChangeTenantId = (tenantId: string | undefined) => {
        setTenantId(tenantId);
    };

    return (
        <PayoutContextProvider>
            <Spin spinning={loadingEditInvoice}>
                <section className="invoice-container">
                    <div className="block-heading edit-heading">
                        <span>{t('quotes.heading.edit_invoice_title')}</span>
                        <div className="header-actions">
                            <Button onClick={goBack}>{t('quotes.actions.cancel')}</Button>
                            <Button
                                type="primary"
                                disabled={status === QuoteStatus.Draft && isLoading}
                                loading={status === QuoteStatus.Pending && isLoading}
                                onClick={() => submitForm(QuoteStatus.Pending)}
                            >
                                {t('actions.save')}
                            </Button>
                        </div>
                    </div>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{ items: [{ qty: 1, margin: 0 }] }}
                        onFinish={onFinish}
                    >
                        <div className="section details-section">
                            <div className="section-header">
                                {t('quotes.heading.invoiceInformation')}
                            </div>
                            <Row gutter={20}>
                                <Col xs={24} md={8} xl={8} className="user-info-col">
                                    <div className="user-info">
                                        <div className="name">{invoiceData?.data?.contactName}</div>
                                        <div className="no-space">
                                            {invoiceData?.data.contactEmail}
                                        </div>
                                        <div className="no-space">
                                            {invoiceData?.data?.contactPhone
                                                ? maskPhone(invoiceData?.data?.contactPhone)
                                                : ''}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={0} xl={2}></Col>
                                <Col xs={24} md={8} xl={7}>
                                    <Form.Item
                                        label={t('quotes.fields.paymentDate.label')}
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.paymentDate.required'),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            getPopupContainer={(trigger) =>
                                                trigger?.parentNode as HTMLElement
                                            }
                                            format={DATE_FORMAT}
                                            disabledDate={(current) => disablePaymentDate(current)}
                                            onChange={() => form.resetFields(['dueDate'])}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8} xl={7}>
                                    <Form.Item
                                        label={t('quotes.fields.paymentDueDate.label')}
                                        name="dueDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: t('quotes.fields.paymentDueDate.required'),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            getPopupContainer={(trigger) =>
                                                trigger?.parentNode as HTMLElement
                                            }
                                            format={DATE_FORMAT}
                                            disabledDate={(current) => disableDueDate(current)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item
                                        label={t('quotes.fields.description.label')}
                                        name="description"
                                    >
                                        <TextEditor height="300px" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <ItemsDetail
                                contact={contactDetail?.data}
                                form={form}
                                dataProviderName={dataProviderName}
                                apiUrl={apiUrl}
                                orderId={null}
                                id={id}
                                tenantId={tenantId}
                                onChangeTenantId={handleChangeTenantId}
                            />
                        </div>
                        <div className="section">
                            <div className="section-header">{t('quotes.fields.tc.label')}</div>
                            <Row gutter={20}>
                                <Col md={24}>
                                    <Form.Item name="termsAndConditions">
                                        <TextEditor />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </section>
            </Spin>
        </PayoutContextProvider>
    );
};
