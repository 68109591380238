import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { IService } from '@interfaces/service';
import { IUser } from '@interfaces/user';
import { CreateButton, useDrawerForm, useSimpleList } from '@refinedev/antd';
import {
    HttpError,
    IResourceComponentsProps,
    useGetIdentity,
    useNavigation,
    usePermissions,
    useTranslate,
} from '@refinedev/core';
import { Col, Form, List, Row } from 'antd';
import { ServiceDetails, ServiceItem } from 'components/service';

export const SERVICES_RESOURCE = 'v1/services';

export const ServiceIndex: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { data: rights } = usePermissions<string[]>();

    const { listProps, searchFormProps } = useSimpleList<IService, HttpError, { name: string }>({
        pagination: { pageSize: 12, current: 1 },
        resource: SERVICES_RESOURCE,
    });

    const { show: detailShow } = useNavigation();

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IService>({
        action: 'create',
        resource: 'v1/services',
        successNotification: { message: 'Successfully created', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            createFormProps.form.resetFields();
        },
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IService>({
        action: 'edit',
        resource: 'v1/services',
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            editFormProps.form.resetFields();
        },
    });

    return (
        <>
            <Form
                {...searchFormProps}
                onValuesChange={() => {
                    searchFormProps.form?.submit();
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                gap: '8px',
                                marginBottom: '16px',
                            }}
                        >
                            <label className="product-header-section">{t('sider.services')}</label>
                            {(rights || []).indexOf(PERMISSIONS.WL_SERVICE_CREATE) >= 0 && (
                                <CreateButton
                                    type="primary"
                                    icon={false}
                                    onClick={() => createShow()}
                                >
                                    <PlusOutlined />
                                    {t('services.buttons.addService')}
                                </CreateButton>
                            )}
                        </div>
                        <List
                            grid={{
                                gutter: 20,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 3,
                                xl: 3,
                                xxl: 4,
                            }}
                            {...listProps}
                            renderItem={(item) => (
                                <ServiceItem
                                    key={item.id}
                                    item={item}
                                    editShow={editShow}
                                    detailShow={detailShow}
                                />
                            )}
                        />
                    </Col>
                </Row>
            </Form>
            <ServiceDetails
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                isEditMode={false}
            />
            <ServiceDetails
                isEditMode={true}
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
        </>
    );
};
