import { useEffect } from 'react';

import { useGetQueryValue } from '../../../../hooks/useGetQueryValue';

export const FinixCallbackPage: React.FC = () => {
    const { identityId } = useGetQueryValue(['identityId']);
    useEffect(() => {
        if (identityId) {
            (window.parent as any)?.['finixCallback']?.(identityId);
        }
    });

    return <section></section>;
};
