import { NReport } from '@interfaces/components/report';
import { capitalizeFirstLetter, splitToUppercase } from '@utils/resource';
import { Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from '../styles.module.scss';

const { Text } = Typography;
interface ISection {
    title: string;
    disputeDetail: NReport.IDisputeDetailResponseDto;
    formatDate: (dateValue: string) => string;
}

const DetailsSection = ({ title, disputeDetail, formatDate }: ISection) => {
    const { t } = useTranslation(['common']);

    return (
        <>
            <Typography.Title level={3}>{title}</Typography.Title>
            <Row gutter={[24, 24]} justify="space-between">
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.respond_by', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {dayjs(disputeDetail?.respondBy).format('M/D/YYYY')}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.last_updated', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {formatDate(`${disputeDetail?.updatedAt}`)}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.pin_debit', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {capitalizeFirstLetter(t('no'))}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.pin_debit_adjustment_number', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {t('-')}
                        </Text>
                    </div>
                </Col>
                <Col xs={24} sm={12} lg={7}>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.merchant_account', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {t('-')}
                        </Text>
                    </div>
                    <div className={styles.disputeFlexRow}>
                        <Text>{t('disputes.processor', { ns: 'common' })}</Text>
                        <Text strong className={styles.disputeBlock}>
                            {splitToUppercase(disputeDetail?.merchant?.processor ?? '-')}
                        </Text>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default DetailsSection;
