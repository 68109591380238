import { getEnvConfig } from 'getEnvConfig';
import Response from '../../data-access/responses/response';
import { FinixErrorCode } from '../../enums/finix-error-code.enum';
import { NFinix } from '../../interfaces/finix';
import BaseApi from './baseApi';

export class FinixPaymentService extends BaseApi {
    constructor() {
        super();
    }

    public async checkoutFinix(
        request: NFinix.IFinixTransferRequest,
        isWL?: boolean,
    ): Promise<{ result: boolean; errorCode?: FinixErrorCode }> {
        const _url = isWL ? getEnvConfig.PORTAL_URL : getEnvConfig.INTERNAL_CRM_API_URL;
        const { data } = await this.post<Response<boolean | NFinix.IFinixErrorResponse>>(
            `${_url}/v1/payments/finix/checkout`,
            request,
        );
        if (typeof data === 'boolean') {
            return {
                result: data,
            };
        }

        if ((data as unknown as NFinix.IFinixErrorResponse)?.response?.error?.length) {
            return {
                result: false,
                errorCode: (data?.data as unknown as NFinix.IFinixErrorResponse)?.response
                    ?.message?.[0]?.failure_code,
            };
        }

        return {
            result: !!data?.data,
        };
    }
}
