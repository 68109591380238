import {
    AdjustmentStatusEnum,
    DisputeResponseStateEnum,
    StateColorEnum,
    UploadStatusEnum,
} from '@enums/disputes.enum';
import { NReport } from '@interfaces/components/report';
import { BaseKey, useApiUrl, useCustomMutation, useNavigation, useOne } from '@refinedev/core';
import Response from '@responses/response';
import { convertToTitleCase, getLocalImageUrl } from '@utils/resource';
import { Button, Card, Divider, Modal, notification, Spin, Table, Tag } from 'antd';
// Custom Components
import { ColumnsType } from 'antd/es/table';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload';
import { formatPriceBaseOnCurrency } from 'common/functions/format-price';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UploadService } from 'services/api/upload.service';

import ModalAcceptDispute from '../modal-accept-disputes';
import TooltipWithCopy from '../tooltip-with-copy';
import BuyerDetails from './buyer-detail';
import DetailsSection from './details-dection-dispute';
import HeaderSectionDispute from './header-section-dispute';
import DisputeOrderDetail from './order-detail';
import OriginalPayment from './original-payment';
import PaymentInstrument from './payment-instrument';
import styles from './styles.module.scss';
import DisputeResponseSection from './upload-section';

const MAX_FILES = 8;

export interface EvidenceFile {
    id: string;
    fileName: string;
    status: UploadStatusEnum;
    fileType: string;
    uploadedOn: string;
}

const DisputeDetail: React.FC = () => {
    const { t } = useTranslation(['common']);
    const { push } = useNavigation();
    const { id } = useParams<{ id: string }>();
    const apiUrl = useApiUrl();

    const [isChecked, setIsChecked] = useState(false);
    const [note, setNote] = useState('');
    const [uploadFiles, setUploadFiles] = useState<EvidenceFile[]>([]);
    const [fileListState, setFileListState] = useState<UploadFile<EvidenceFile>[]>([]);
    const [responseState, setResponseState] = useState<string>('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { mutate: customMutation, isLoading: actionLoading } =
        useCustomMutation<Response<NReport.IDisputeDetailResponseDto>>();
    const {
        data: disputeDetail,
        isLoading,
        refetch,
        isFetching,
    } = useOne<NReport.IDisputeDetailResponseDto>({
        resource: 'v1/disputes',
        id: id as BaseKey,
        errorNotification: false,
        queryOptions: { enabled: false },
    });

    const _uploadService = new UploadService();

    useEffect(() => {
        if (id) {
            refetch();
        }
    }, [id]);

    useEffect(() => {
        if (disputeDetail?.data) {
            setUploadFiles(formatEvidenceFiles(disputeDetail.data.evidences));
            setResponseState(disputeDetail.data.responseState);
        }
    }, [disputeDetail]);

    const formatEvidenceFiles = (evidences: NReport.IEvidences[] = []): EvidenceFile[] =>
        evidences.map((evidence) => ({
            id: evidence.id,
            fileName: evidence.fileName,
            status:
                evidence.state?.toLowerCase() === UploadStatusEnum.SUCCEEDED.toLowerCase()
                    ? UploadStatusEnum.UPLOADED
                    : UploadStatusEnum.PENDING,
            fileType: evidence.fileExtension.split('.').pop()?.toUpperCase() || '',
            uploadedOn: formatDate(evidence.createdAt),
        }));

    const formatDate = (date: string) => dayjs(date).format('M/D/YYYY h:mm A');
    const daysRemaining = dayjs(disputeDetail?.data?.respondBy)
        .startOf('day')
        .diff(dayjs().startOf('day'), 'day');

    const toggleModalVisibility = () => setIsModalVisible((prev) => !prev);

    const showNotification = debounce((message: string, description: string) => {
        notification.error({ message, description });
    }, 300);

    const handleFileUpload: UploadProps['onChange'] = ({ fileList }) => {
        if (uploadFiles.length + fileList.length > MAX_FILES) {
            showNotification(
                t('disputes.upload_limit_error', { ns: 'common' }),
                t('disputes.upload_limit', { ns: 'common', limit: MAX_FILES }),
            );
            return;
        }
        setFileListState(fileList);
        debouncedUploadFiles(fileList);
    };

    const debouncedUploadFiles = useCallback(
        debounce((files: UploadFile<EvidenceFile>[]) => {
            const filesToUpload = files
                .map((file) => file.originFileObj)
                .filter(Boolean) as RcFile[];
            if (filesToUpload.length > 0) customUploadHandler(filesToUpload);
        }, 300),
        [uploadFiles],
    );
    const customUploadHandler = async (fileList: RcFile[]) => {
        const formData = new FormData();
        fileList.forEach((file) => formData.append('files', file));
        formData.append('disputeId', id as string);

        customMutation(
            {
                url: `${apiUrl}/v1/disputes/upload`,
                method: 'post',
                values: formData,
                errorNotification: false,
            },
            { onSuccess: onUploadSuccess, onError: onUploadError },
        );
    };

    const onUploadSuccess = (data: any) => {
        if (data?.data) {
            setUploadFiles((prev) => [...formatEvidenceFiles(data.data), ...prev]);
            setFileListState([]);
        } else {
            showNotification(
                t('disputes.upload_error', { ns: 'common' }),
                t('disputes.upload_error_msg', { ns: 'common' }),
            );
        }
    };

    const onUploadError = (error: any) => {
        const message = error?.response?.data?.response?.message;
        showNotification(
            t('disputes.upload_error', { ns: 'common' }),
            message && message.includes('_')
                ? t(`disputes.${message}`, { ns: 'common' })
                : t('disputes.upload_error_msg', { ns: 'common' }),
        );
        setFileListState([]);
    };

    const handleDownload = async (evidenceId: string, type: string, fileName: string) => {
        if (!disputeDetail?.data?.id) return;
        setIsDownloading(true);

        try {
            const payload = { disputeId: disputeDetail.data.id, evidenceId };
            const contentType = type.toLowerCase() === 'pdf' ? 'application/pdf' : 'image/jpg';
            const response = await _uploadService.downloadImageEvidence(payload);
            downloadFile(response, contentType, fileName);
        } catch {
            notification.error({
                message: 'Download Error',
                description: 'Failed to download evidence.',
            });
        } finally {
            setIsDownloading(false);
        }
    };

    const downloadFile = (response: any, contentType: string, fileName: string) => {
        if (response?.data?.data?.length) {
            const blob = new Blob([new Uint8Array(response.data.data)], { type: contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            URL.revokeObjectURL(url);
        }
    };

    const submitAction = (url: string, successMessage: string, errorMessage: string) => {
        customMutation(
            {
                url,
                method: 'post',
                values: { disputeId: disputeDetail?.data?.id, note },
                successNotification: { message: successMessage, type: 'success' },
                errorNotification: { message: errorMessage, type: 'error' },
            },
            {
                onSuccess: (data) =>
                    data?.data?.isSuccess && setResponseState(data.data.data?.responseState),
            },
        );
    };

    const acceptDispute = () => {
        submitAction(
            `${apiUrl}/v1/disputes/accept`,
            t('disputes.accept_success', { ns: 'common' }),
            t('disputes.accept_error', { ns: 'common' }),
        );
        toggleModalVisibility();
    };

    const getResponseState = (responseState: string) => {
        if (responseState === DisputeResponseStateEnum.ACCEPTED) {
            return t('disputes.liability_accepted', { ns: 'common' });
        } else if (responseState === DisputeResponseStateEnum.RESPONDED) {
            return t('disputes.evidence_submitted', { ns: 'common' });
        }

        return t('disputes.needs_response', { ns: 'common' });
    };

    const stateColor = (state: string): StateColorEnum => {
        switch (convertToTitleCase(state)) {
            case AdjustmentStatusEnum.SUCCEEDED:
                return StateColorEnum.GREEN;
            case AdjustmentStatusEnum.CANCELED:
                return StateColorEnum.RED;
            default:
                return StateColorEnum.BLUE;
        }
    };

    const adjustmentColumns: ColumnsType<NReport.IAdjustments> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => (
                <span className="cursor-pointer">
                    <TooltipWithCopy text={text} label="ID" />
                </span>
            ),
            width: 50,
        },

        {
            title: t('disputes.created_at', { ns: 'common' }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            render: (createdAt: string) => (
                <div>
                    <div>{dayjs(createdAt).format('M/D/YYYY')}</div>
                    <div className={styles.textDate}>{dayjs(createdAt).format('h:mm A')}</div>
                </div>
            ),
            width: 200,
        },
        {
            title: t('disputes.ready_to_settle', { ns: 'common' }),
            dataIndex: 'readyToSettleAt',
            key: 'readyToSettleAt',
            render: (readyToSettleAt: string) => (
                <div>
                    <div>{dayjs(readyToSettleAt).format('M/D/YYYY')}</div>
                    <div className={styles.textDate}>{dayjs(readyToSettleAt).format('h:mm A')}</div>
                </div>
            ),
            width: 200,
        },
        {
            title: t('disputes.amount', { ns: 'common' }),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => (
                <>
                    <span className={styles.disputeTextBold}>
                        {formatPriceBaseOnCurrency(amount || 0)}
                    </span>
                    <span className={`${styles.headerSettlementTitle}`}>
                        {t(`disputes.currency.usd`, { ns: 'common' })}
                    </span>
                </>
            ),
            width: 200,
        },
        {
            title: t('disputes.adjustment_type', { ns: 'common' }),
            dataIndex: 'adjustmentType',
            key: 'adjustmentType',
            render: (adjustmentType: string) => (
                <span>{adjustmentType ? convertToTitleCase(adjustmentType) : '-'}</span>
            ),
            width: 150,
        },
        {
            title: t('disputes.level', { ns: 'common' }),
            dataIndex: 'level',
            key: 'level',
            render: (level: string) => <span>{level ? convertToTitleCase(level) : '-'}</span>,
        },
        {
            title: t('disputes.state', { ns: 'common' }),
            dataIndex: 'state',
            key: 'state',
            render: (state: string) => (
                <Tag color={stateColor(state)}>
                    {t(`disputes.adjustment_state.${state.toLowerCase()}`, { ns: 'common' })}
                </Tag>
            ),
        },
    ];

    // Memoize props for DisputeResponseSection
    const disputeResponseProps = useMemo(
        () => ({
            responseState,
            daysRemaining,
            uploadFiles,
            fileListState,
            handleFileChange: handleFileUpload,
            handleDownload,
            submitEvidence: () =>
                submitAction(
                    `${apiUrl}/v1/disputes/submit`,
                    t('disputes.submit_evidence_success', { ns: 'common' }),
                    t('disputes.submit_evidence_error', { ns: 'common' }),
                ),
            note,
            setNote,
            showModalAcceptLiability: toggleModalVisibility,
            getResponseState,
        }),
        [responseState, daysRemaining, uploadFiles, fileListState, note, apiUrl],
    );

    return (
        <Spin spinning={isLoading || isFetching}>
            {disputeDetail && (
                <>
                    <div
                        className="flex items-center cursor-pointer pb-2"
                        onClick={() => push('/wl/reports/disputes')}
                    >
                        <img
                            src={'/images/icons/back.svg'}
                            alt="back"
                            className="cursor-pointer ml-1 mr-2"
                        />
                        <span className="body-2">
                            {t('disputes.back_to_disputes', { ns: 'common' })}
                        </span>
                    </div>
                    <Card className={styles.disputeDetail}>
                        <HeaderSectionDispute disputeDetail={disputeDetail.data} />
                        <Divider />
                        <Spin
                            spinning={actionLoading || isDownloading}
                            tip={t('disputes.uploading_loading', { ns: 'common' })}
                            className="w-full"
                        >
                            <DisputeResponseSection {...disputeResponseProps} />
                        </Spin>
                        <Divider />
                        <DetailsSection
                            title={t('disputes.dispute_details', { ns: 'common' })}
                            disputeDetail={disputeDetail.data}
                            formatDate={formatDate}
                        />
                        <Divider />
                        {disputeDetail.data.originalPayment && (
                            <OriginalPayment
                                title={t('disputes.original_payment', { ns: 'common' })}
                                amount={disputeDetail.data.amount}
                                originalPayment={disputeDetail.data.originalPayment}
                                formatDate={formatDate}
                            />
                        )}
                        <Divider />
                        {disputeDetail.data.buyerDetails && (
                            <BuyerDetails
                                title={t('disputes.buyer_details', { ns: 'common' })}
                                buyerDetails={disputeDetail.data.buyerDetails}
                            />
                        )}
                        <Divider />
                        {disputeDetail.data.paymentInstrument && (
                            <PaymentInstrument
                                title={t('disputes.payment_instrument', { ns: 'common' })}
                                instrument={disputeDetail.data.paymentInstrument}
                                formatDate={formatDate}
                                createdVia={disputeDetail.data.originalPayment?.createdVia}
                            />
                        )}
                        <Divider />
                        {disputeDetail.data.adjustments &&
                            disputeDetail.data.adjustments.length > 0 && (
                                <Table
                                    columns={adjustmentColumns}
                                    dataSource={disputeDetail.data.adjustments}
                                    pagination={false}
                                    scroll={{ x: '1024px', y: 400 }}
                                    bordered
                                    className={styles.tableSettlement}
                                />
                            )}
                        <Divider />
                        {disputeDetail.data?.orderDetail && (
                            <DisputeOrderDetail
                                title={t('disputes.invoice_detail', { ns: 'common' })}
                                orderDetail={disputeDetail.data.orderDetail}
                            />
                        )}
                    </Card>
                    <Modal
                        title={t('disputes.accept_dispute_link', { ns: 'common' })}
                        visible={isModalVisible}
                        onOk={acceptDispute}
                        onCancel={toggleModalVisibility}
                        footer={[
                            <Button key="cancel" onClick={toggleModalVisibility}>
                                {t('cancel')}
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                onClick={acceptDispute}
                                disabled={!isChecked}
                            >
                                {t('disputes.accepting_liability', { ns: 'common' })}
                            </Button>,
                        ]}
                    >
                        <ModalAcceptDispute
                            setIsChecked={setIsChecked}
                            setNote={setNote}
                            note={note}
                            isChecked={isChecked}
                        />
                    </Modal>
                </>
            )}
        </Spin>
    );
};

export default DisputeDetail;
