import Disputes from '@components/disputes';
import { IResourceComponentsProps } from '@refinedev/core';
import { FilterContextProvider } from 'contexts/filter.context';

export const DisputesIndex: React.FC<IResourceComponentsProps> = () => {
    return (
        <FilterContextProvider>
            <Disputes />
        </FilterContextProvider>
    );
};
