import { Subscribe } from '@components/internal-crm/mqttt-subscribe/mqtt-subscribe';
import { ExportTypeEnum } from '@enums/export-file.enum';
import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { useApiUrl, useCustom } from '@refinedev/core';
import { assignLayoutTable, defaultStyle, footer, header, headerBody, styles } from '@utils/pdf';
import { Button, notification } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, Node, TDocumentDefinitions } from 'pdfmake/interfaces';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DownloadPrintProps = {
    title: string;
    contactName?: string;
    dataToPrint?: NPDFMakeSetting.IReportBody;
    filter?: string;
    reportType?: ExportTypeEnum | string;
    dateRange?: [string, string];
    timeZone?: string;
    settlementId?: string;
    isExportExcel?: boolean;
};

export const DownloadPrint: FC<DownloadPrintProps> = ({
    title,
    contactName,
    dataToPrint,
    filter,
    reportType,
    dateRange,
    timeZone,
    settlementId,
    isExportExcel,
}) => {
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const currentUser = auth?.id;
    const [userId, setUserId] = useState<string>();
    const [loading, setLoading] = useState(false);

    const [content, setContent] = useState<Content[]>([]);
    const objDefinition: TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [40, 80, 40, 80],
        content: content,
        styles: styles(),
        header: header(title, ''),
        defaultStyle: defaultStyle(),
        footer: (currentPage: number) => footer(currentPage),
        pageBreakBefore: function (currentNode: Node, followingNodesOnPage: Array<Node>) {
            return (
                currentNode.headlineLevel === 1 &&
                !followingNodesOnPage.filter((node: Node) => node.headlineLevel === 3).length
            );
        },
    };
    const { t } = useTranslation('common');
    useEffect(() => {
        const newContent: Content[] = [];

        const columnData: any = headerBody((rs: string, obj: any) => t(rs, obj), contactName);
        if (dataToPrint?.summarySection?.length) {
            dataToPrint.summarySection.forEach((item: any) => {
                columnData.push(item);
            });
        }
        newContent.push(columnData);
        if (dataToPrint?.tableBody.length) {
            dataToPrint?.tableBody.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }

        if (dataToPrint?.tableBodyQty?.length) {
            dataToPrint?.tableBodyQty.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }
        setContent(newContent);
    }, [contactName, dataToPrint]);

    const handleDownload = () => {
        const pdfDocGenerator = pdfMake.createPdf(objDefinition);
        pdfDocGenerator.download(`${title}.pdf`);
    };

    const onMessage = useCallback((message: string) => {
        const newMessage = JSON.parse(message);
        if (newMessage.data.path !== '') {
            setUserId(undefined);
            window.location.replace(newMessage.data.path);
            setLoading(false);
        }
    }, []);

    const { data: exportPath, refetch: getExportReport } = useCustom<any>({
        dataProviderName,
        url: `${apiUrl}/v1/export`,
        method: 'get',
        queryOptions: { enabled: false },
        config: {
            filters: [
                {
                    field: 'dateRange',
                    operator: 'eq',
                    value: dateRange,
                },
                {
                    field: 'type',
                    operator: 'eq',
                    value: reportType,
                },

                {
                    field: 'timeZone',
                    operator: 'eq',
                    value: timeZone,
                },

                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },

                {
                    field: 'settlementId',
                    operator: 'eq',
                    value: settlementId,
                },
            ],
        },
    });

    useEffect(() => {
        if (exportPath?.data.data.status == 'completed') {
            setUserId(undefined);
            window.location.replace(`${exportPath?.data.data.path}`);
        }
    }, [exportPath]);

    const exportToExcel = () => {
        setUserId(currentUser);
        getExportReport();
        notification.success({
            className: 'success-notification',
            message: '',
            description: t('export_processing', { ns: 'common' }),
        });
        setLoading(true);
    };

    return (
        <div className={`flex justify-between items-center md:ml-4 export-report`}>
            {isExportExcel ? (
                <Button
                    disabled={loading}
                    type="link"
                    icon={<img src="/images/icons/export-excel.svg" />}
                    className="mr-3 button-download"
                    onClick={exportToExcel}
                />
            ) : (
                <Button
                    type="link"
                    icon={<img src="/images/icons/download.svg" />}
                    className="mr-2"
                    onClick={handleDownload}
                />
            )}

            {userId ? <Subscribe onMessage={onMessage} currentUser={userId} /> : <></>}
        </div>
    );
};
