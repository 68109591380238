import './styles.scss';

import { DeleteOutlined } from '@ant-design/icons';
import { WlInvoicesMenuSidebarIcon } from '@components/layout/sidebar/icons/white_label/invoices-menu-icon';
import { WlPayoutMenuSideBarIcon } from '@components/layout/sidebar/icons/white_label/payout-menu-icon';
import { CustomSearch } from '@components/modules/custom-search';
import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { SelectPartner } from '@components/modules/select-partner';
import { PaymentMethod } from '@components/setting/payment-method';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { SubscriptionStatus } from '@enums/subscription-status.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IMarketplace } from '@interfaces/marketplace';
import { IPartner } from '@interfaces/partner';
import { ISubscription } from '@interfaces/subscription';
import { IUser } from '@interfaces/user';
import {
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
    useModal,
} from '@refinedev/antd';
import {
    LogicalFilter,
    useCreate,
    useCustom,
    useGetIdentity,
    usePermissions,
    useTable,
    useUpdate,
} from '@refinedev/core';
import { loadStripe } from '@stripe/stripe-js';
import { convertPrice } from '@utils/resource';
import {
    Button,
    Dropdown,
    Menu,
    Modal,
    Popconfirm,
    Select,
    Table,
    TablePaginationConfig,
    Tooltip,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { getEnvConfig } from 'getEnvConfig';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAppSubscriptionPeriodEnd, RECURRING_SUBSCRIPTION_SUBTRACT_DAY } from 'utils/date';

const LIST_FILTER_STATUS = [
    {
        label: 'Active',
        value: SubscriptionStatus.Active.toString(),
    },
    {
        label: 'Cancel',
        value: SubscriptionStatus.Cancel.toString(),
    },
    {
        label: 'Payment Failed',
        value: SubscriptionStatus.PaymentFailed.toString(),
    },
];

export const SubscriptionIndex: React.FC = () => {
    const { mutate } = useUpdate();
    const { data: user } = useGetIdentity<IUser.IUserDto>();

    const { t: translate } = useTranslation(['contact', 'common', 'quote']);
    const { data: permissionsData } = usePermissions<string[]>();
    const [partnerId, setPartnerId] = useState<string>();
    const [contactId, setContactId] = useState<string>();
    const [contact, setContact] = useState<any[]>([]);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const { modalProps, show, close } = useModal({
        modalProps: {
            onCancel: () => {
                setClientSecret(null);
            },
        },
    });
    const apiUrl = getEnvConfig.PORTAL_URL;

    const stripePromise = loadStripe(getEnvConfig.STRIPE_PUBLIC_KEY);

    const { mutate: createPaymentIntent } = useCreate();

    const handleUpdatePayment = (record: ISubscription.SubscriptionList) => {
        show();
        createPaymentIntent(
            {
                resource: `v1/sale-subscription/payment/${record?.id}`,
                values: {},
                errorNotification: false,
                successNotification: false,
            },
            {
                onError: (error) => {
                    // An error happened!
                    console.error(error);
                },
                onSuccess: (res) => {
                    const secret = res?.data?.data as string;
                    setClientSecret(secret);
                },
            },
        );
    };

    const onPaymentIntentSuccess = () => {
        // close modal reload subscriptions
        close();
        setClientSecret(null);

        //update subscription
    };
    const { data: contactsData, refetch: contactRefetch } = useCustom<IPartner.IPartnerInfo[]>({
        url: `${apiUrl}/v1/contacts/list`,
        method: 'get',
        config: {
            filters: [
                {
                    field: 'partnerId',
                    operator: 'eq',
                    value: partnerId,
                },
            ],
        },
        queryOptions: {
            enabled: false,
        },
    });

    useEffect(() => {
        if (partnerId) {
            contactRefetch();
        }
    }, [partnerId]);

    useEffect(() => {
        if (user && !partnerId) {
            setPartnerId(user.id);
        }
    }, []);
    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<ISubscription.SubscriptionList>({
        resource: 'v1/sale-subscription',

        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
    });

    const menu = (id: string, record: ISubscription.SubscriptionList) => {
        return (
            <Menu mode="vertical">
                {(permissionsData || []).includes(PERMISSIONS.WL_INVOICE_SHOW) ? (
                    <Menu.Item
                        key="0"
                        icon={
                            <ShowButton
                                resource="wl_invoice"
                                recordItemId={record.invoice?.id}
                                children={translate('subscriptions.action.viewInvoice', {
                                    ns: 'common',
                                })}
                                className="icon-action-subscription-list"
                                icon={<WlInvoicesMenuSidebarIcon />}
                                style={{
                                    padding: '5px 16px 5px 12px',
                                    margin: 0,
                                }}
                            />
                        }
                        style={{ padding: 0 }}
                    ></Menu.Item>
                ) : null}
                {(permissionsData || []).includes(PERMISSIONS.WL_RECURRING_INVOICE_SHOW) ? (
                    <Menu.Item
                        key="1"
                        icon={
                            <ShowButton
                                recordItemId={record.id}
                                children={translate('subscriptions.action.viewBillingHistory', {
                                    ns: 'common',
                                })}
                                className="icon-action-subscription-list"
                                icon={<WlPayoutMenuSideBarIcon />}
                                style={{
                                    padding: '5px 24px 5px 12px',
                                    margin: 0,
                                }}
                            />
                        }
                        style={{ padding: 0 }}
                    ></Menu.Item>
                ) : null}
                {record.status === SubscriptionStatus.Active &&
                    (permissionsData || [])?.indexOf(PERMISSIONS.WL_RECURRING_INVOICE_EDIT) >=
                        0 && (
                        <Menu.Item
                            key="2"
                            icon={
                                <Popconfirm
                                    key="delete"
                                    okText={'Sure'}
                                    cancelText={'Cancel'}
                                    title={'Are you sure?'}
                                    onConfirm={(): void => {
                                        mutate({
                                            resource: 'v1/sale-subscription/cancel',
                                            id: record.subscriptionId,
                                            values: '',
                                            successNotification: (data) => {
                                                tableQueryResult.refetch();
                                                return {
                                                    message: translate(
                                                        'subscriptions.cancel_subscription_successfully',
                                                        {
                                                            ns: 'common',
                                                        },
                                                    ),
                                                    type: 'success',
                                                };
                                            },
                                            errorNotification: (data) => {
                                                return {
                                                    message:
                                                        'subscriptions.cancel_subscription_fail',
                                                    type: 'error',
                                                };
                                            },
                                        });
                                    }}
                                >
                                    <Button
                                        icon={<DeleteOutlined className="mr-2" />}
                                        style={{
                                            padding: '5px 16px 5px 12px',
                                            margin: 0,
                                        }}
                                    >
                                        {translate('subscriptions.action.cancel', {
                                            ns: 'common',
                                        })}
                                    </Button>
                                </Popconfirm>
                            }
                            style={{ padding: 0 }}
                        ></Menu.Item>
                    )}
                {record.status === SubscriptionStatus.PaymentFailed ? (
                    <Menu.Item
                        style={{ padding: 0 }}
                        key="3"
                        icon={
                            <Button
                                onClick={() => handleUpdatePayment(record)}
                                className="icon-action-subscription-list"
                                icon={
                                    <img
                                        src="/images/icons/edit.svg"
                                        alt="edit"
                                        className="cursor-pointer mr-2"
                                    />
                                }
                                style={{
                                    padding: '5px 24px 5px 12px',
                                    margin: 0,
                                }}
                            >
                                <span>
                                    {translate('subscriptions.action.update_payment', {
                                        ns: 'common',
                                    })}
                                </span>
                            </Button>
                        }
                    ></Menu.Item>
                ) : null}
            </Menu>
        );
    };

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }

            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        tableFilters: Record<
            string,
            (string | number | boolean) | (string | number | boolean)[] | null
        >,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const onClearPartner = () => {
        setPartnerId(user?.id);
        onClearContact();
        setFilters([
            {
                field: 'partnerId',
                operator: 'eq',
                value: undefined,
            },
        ]);
    };

    const onClearContact = () => {
        setFilters([
            {
                field: 'contactId',
                operator: 'eq',
                value: undefined,
            },
        ]);
    };

    const handleChangeFilter = (partnerId: string) => {
        setPartnerId(partnerId);
        setFilters([
            {
                field: 'partnerId',
                operator: 'eq',
                value: partnerId,
            },
        ]);
        setFilters([
            {
                field: 'contactId',
                operator: 'eq',
                value: undefined,
            },
        ]);
    };

    useEffect(() => {
        setContactId(undefined);
        if (partnerId) {
            setContact(
                (contactsData?.data || []).map((contact: any) => {
                    const name = `${contact.firstName} ${contact.lastName}`;
                    return {
                        label: name.length > 20 ? name.substring(0, 20) + '...' : name,
                        value: contact.id,
                    };
                }),
            );
        }
    }, [partnerId, contactsData?.data]);
    return (
        <>
            <section className="item-list-container subscription-page-wrapper ">
                <div className="list-header">
                    <div className="filter-wrapper">
                        <CustomSearch
                            placeholder={translate('quotes.fields.search.invoce_placeholder', {
                                ns: 'quote',
                            })}
                            className={'search-item custom-width '}
                            onChange={(event) => onChangeFilter(event)}
                            defaultValue={
                                filters?.find((f) => (f as LogicalFilter).field === 'filter')?.value
                            }
                            allowClear={true}
                        />
                        <FilterStatus
                            placeholder={translate('quotes.fields.search.status_placeholder', {
                                ns: 'quote',
                            })}
                            listStatus={LIST_FILTER_STATUS}
                            className="search-status"
                            defaultValue={
                                filters?.find((f) => (f as LogicalFilter).field === 'status')?.value
                            }
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                        />
                        {user?.userType === UserTypeEnum.TENANT && (
                            <>
                                <SelectPartner
                                    key="1"
                                    onChange={(option, _) => {
                                        handleChangeFilter(option);
                                    }}
                                    allowClear
                                    placeholder={translate(
                                        'contacts.fields.search.placeholder_partner',
                                        {
                                            ns: 'contact',
                                        },
                                    )}
                                    getSelectedPartner={(partner) => {
                                        if (partner) {
                                            handleChangeFilter(partner.id ?? '');
                                        }
                                    }}
                                    className="search-item custom-width"
                                    clearPartner={onClearPartner}
                                />

                                <Select
                                    className="filter-status-component"
                                    showSearch={false}
                                    allowClear
                                    onChange={(option, _) => {
                                        setContactId(option);
                                        setFilters([
                                            {
                                                field: 'contactId',
                                                operator: 'eq',
                                                value: option,
                                            },
                                        ]);
                                    }}
                                    placeholder={translate(
                                        'contacts.fields.search.placeholder_contact',
                                        {
                                            ns: 'contact',
                                        },
                                    )}
                                    value={contactId}
                                    options={contact}
                                    onClear={onClearContact}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            tableLayout="fixed"
                            scroll={{ x: '900px' }}
                            rowKey="id"
                        >
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                                title={
                                    <>
                                        {translate('subscriptions.invoiceId', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="invoice"
                                key="incrementId"
                                width={140}
                                render={(invoice, _) => {
                                    const incrementId = invoice.incrementId;

                                    return <p className="table-tbody-text">{incrementId}</p>;
                                }}
                            />
                            <Table.Column
                                title={
                                    <>
                                        {translate('subscriptions.statusTitle', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="status"
                                key="status"
                                width={120}
                                render={(status, _) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {translate(
                                                'subscriptions.status.' + status.toString(),
                                                {
                                                    ns: 'common',
                                                },
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={
                                    <>
                                        {translate('subscriptions.productName', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="invoiceItem"
                                key="productName"
                                width={300}
                                render={(invoiceItem, _) => {
                                    const productName = invoiceItem.productName;

                                    return (
                                        <p className="table-tbody-text">
                                            <Tooltip title={productName}>{productName}</Tooltip>
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={
                                    <>
                                        {translate('subscriptions.nextInvoiceDate', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="currentPeriodEnd"
                                key="nextInvoiceDate"
                                width={200}
                                render={(
                                    nextInvoiceDate,
                                    record: IMarketplace.ISubscriptionInvoice,
                                ) => {
                                    return (
                                        <p className="table-tbody-text">
                                            {/* System auto add extra 1 day to period end of recurring subscription 
                                                So need to subtract 1 day when showing in UI for correct data
                                            */}
                                            {formatAppSubscriptionPeriodEnd(
                                                RECURRING_SUBSCRIPTION_SUBTRACT_DAY,
                                                nextInvoiceDate as Date,
                                                record,
                                            )}
                                        </p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={
                                    <>
                                        {translate('subscriptions.price', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="invoiceItem"
                                key="price"
                                width={150}
                                render={(invoiceItem, _) => {
                                    const price = invoiceItem.total;

                                    return (
                                        <p className="table-tbody-text">{convertPrice(price)}</p>
                                    );
                                }}
                            />
                            <Table.Column
                                title={
                                    <>
                                        {translate('subscriptions.frequencyTitle', {
                                            ns: 'common',
                                        })}
                                    </>
                                }
                                dataIndex="invoiceItem"
                                key="frequency"
                                width={150}
                                render={(invoiceItem, _) => {
                                    const frequency = translate(
                                        'subscriptions.frequency.' +
                                            invoiceItem?.frequency.toString(),
                                        {
                                            ns: 'common',
                                        },
                                    );

                                    return <p className="table-tbody-text">{frequency}</p>;
                                }}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(id, record: ISubscription.SubscriptionList) => (
                                    <div className="flex justify-between items-center">
                                        {(permissionsData || []).some((p) =>
                                            [
                                                PERMISSIONS.WL_INVOICE_SHOW,
                                                PERMISSIONS.WL_RECURRING_INVOICE_SHOW,
                                                PERMISSIONS.WL_RECURRING_INVOICE_EDIT,
                                            ].includes(p),
                                        ) ? (
                                            <Dropdown
                                                overlay={menu(id, record)}
                                                placement="bottomRight"
                                                className="w-8 ml-auto"
                                                arrow
                                                overlayClassName="contact-dropdown-container"
                                                trigger={['click']}
                                            >
                                                <div className="">
                                                    <img
                                                        src="/images/icons/dots-vertical.svg"
                                                        alt="more"
                                                        className="cursor-pointer"
                                                    />
                                                </div>
                                            </Dropdown>
                                        ) : null}
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>

            <Modal
                {...modalProps}
                title="Payment"
                maskClosable={false}
                width={650}
                wrapClassName="payment-modal"
                footer={false}
            >
                <PaymentMethod />
            </Modal>
        </>
    );
};
