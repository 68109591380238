export enum StateInstrumentEnum {
    ENABLE = 'Enable',
    DISABLE = 'Disable',
}

export enum IssuerCountryDisputeEnum {
    NON_USA = 'NON_USA',
    US = 'US',
    USA = 'USA',
    INTERNATIONAL = 'International',
    UNITED_STATES = 'United States',
}

export enum OriginalPaymentStatusEnum {
    SUCCEEDED = 'Succeeded',
}

export enum AdjustmentStatusEnum {
    SUCCEEDED = 'Succeeded',
    CANCELED = 'Canceled',
}

export enum DisputeResponseStateEnum {
    NEEDS_RESPONSE = 'NEEDS_RESPONSE',
    ACCEPTED = 'ACCEPTED',
    RESPONDED = 'RESPONDED',
    PENDING = 'PENDING',
}

export enum UploadStatusEnum {
    UPLOADED = 'Uploaded',
    PENDING = 'Pending',
    SUCCEEDED = 'Succeeded',
}

export enum StateColorEnum {
    GREEN = 'green',
    RED = 'red',
    BLUE = 'blue',
}

export enum CardTypeEnum {
    VISA = 'Visa',
    MASTERCARD = 'MasterCard',
    AMERICAN_EXPRESS = 'American Express',
    DISCOVER = 'Discover',
}
