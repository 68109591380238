import './styles.scss';

import { IResourceComponentsProps, useGetIdentity, usePermissions } from '@refinedev/core';
import { Col, Row } from 'antd';

import { ROOT_TENANT, SA_ROLE } from '../../constants/index.constant';
import { PERMISSIONS } from '../../constants/permission';
import { UserTypeEnum } from '../../enums/user-type.enum';
import { IUser } from '../../interfaces/user';
import { SaRevenueIndex } from '../sa-revenue';
import { ApplicationIndex } from './application';
import { IntroduceIndex } from './introduce';
import { LatestNotificationIndex } from './latest-notification';
interface IDashboardOptions {
    name: string;
}
export const DashboardIndex: React.FC<
    IResourceComponentsProps<unknown, IDashboardOptions>
> = () => {
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const isSystemAdmin =
        userIdentity?.userType === UserTypeEnum.TENANT && userIdentity?.tenantId === ROOT_TENANT;
    const { data: userRights } = usePermissions<string[]>();
    return (
        <>
            {isSystemAdmin ? (
                <SaRevenueIndex />
            ) : (
                <>
                    <section className="header-container mb-6">
                        <IntroduceIndex />
                    </section>

                    <section className="content-container">
                        <Row gutter={16}>
                            {userIdentity?.userType === UserTypeEnum.TENANT &&
                                userRights?.includes(PERMISSIONS.MARKETPLACE_LIST) && (
                                    <Col sm={12} xs={24}>
                                        <ApplicationIndex />
                                    </Col>
                                )}

                            <Col sm={12} xs={24}>
                                <LatestNotificationIndex />
                            </Col>
                        </Row>
                    </section>
                </>
            )}
        </>
    );
};
