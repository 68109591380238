import '../contact/styles.scss';

import { ContactCrmDetail } from '@components/internal-crm/contact';
import { ContactCrmInvoicesPayments } from '@components/internal-crm/contact/contact-invoices-payments';
import { FacebookIcon } from '@components/layout/icons/facebook';
import { TwitterIcon } from '@components/layout/icons/twitter';
import { WebsiteIcon } from '@components/layout/icons/website';
import { PERMISSIONS } from '@constants/permission';
import { IContact } from '@interfaces/contact';
import { useDrawerForm } from '@refinedev/antd';
import { BaseKey, useNavigation, useOne, usePermissions } from '@refinedev/core';
import { getPublicMediaUrl } from '@utils/resource';
import { maskPhone } from '@utils/string';
import { Avatar, Button, Col, Row, Space, Spin } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PartnerCrmDetail } from '../../../components/internal-crm/partner/detail';
import { PartnerCrmInsights } from '../../../components/internal-crm/partner/partner-insights';
import { PartnerCrmPayouts } from '../../../components/internal-crm/partner/partner-payouts';
import { PartnerTypeEnum } from '../../../enums/partner-type.enum';
import { IPartner } from '../../../interfaces/partner';

export const PartnerDetailCrmPage: React.FC = () => {
    const { t } = useTranslation(['contact', 'common', 'marketplace', 'partner']);
    const partnerType = PartnerTypeEnum.RESELLER;
    const { goBack, list, push } = useNavigation();

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;

    const { id } = useParams();
    if (!id) {
        list('wl_partner');
        return <></>;
    }
    const { data, error, isLoading, refetch } = useOne<IPartner.IPartnerInfo>({
        dataProviderName,
        resource: 'v1/partner/byId',
        id: id as BaseKey,
        errorNotification: false,
        queryOptions: {
            retry: 1,
        },
    });

    const [partner, setPartner] = useState<IPartner.IPartnerInfo | null>(null);
    const { data: permissions } = usePermissions<string[]>();

    useEffect(() => {
        if (data?.data) {
            setPartner(data.data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            const { statusCode } = error as { statusCode: number };
            if (statusCode === 404) {
                push('/404');
            }
        }
    }, [error]);
    const renderField = (
        label: string,
        value = '--',
        isPhoneField = false,
        isLinkField = false,
    ) => {
        if (!value) {
            value = '--';
        }
        return (
            <>
                <div className="label"> {label}</div>
                {isPhoneField ? (
                    <div className="value mt-1">
                        <a href={`${value === '--' ? '' : `tel:${value}`}`}>{maskPhone(value)}</a>
                    </div>
                ) : isLinkField ? (
                    <div className="value mt-1">
                        {value === '--' ? (
                            <p>{value}</p>
                        ) : (
                            <a href={value} target="blank">
                                {value}
                            </a>
                        )}
                    </div>
                ) : (
                    <div className="value mt-1"> {value}</div>
                )}
            </>
        );
    };

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IPartner.IPartnerInfo>({
        dataProviderName,
        action: 'edit',
        resource: `v1/partner/${partnerType}`,
        successNotification: { message: 'Successfully edited', type: 'success' },
        redirect: false,
        onMutationSuccess: () => {
            refetch();
        },
    });

    const socialUrl = (social: 'website' | 'twitter' | 'facebook', profile: string): string => {
        if (!profile || profile.includes('http')) return profile;

        switch (social) {
            case 'website':
                return `https://${profile}`;
            case 'twitter':
                return `https://twitter.com/${profile}`;
            case 'facebook':
                return `https://facebook.com/${profile}`;
            default:
                return profile;
        }
    };

    return !isLoading && !error ? (
        <>
            <PartnerCrmDetail
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
                isEditMode={true}
                type={partnerType}
            />
            <div>
                <div
                    className="flex items-center cursor-pointer pb-2"
                    onClick={() => {
                        goBack();
                    }}
                >
                    <img
                        sizes="24"
                        src="/images/icons/back.svg"
                        alt="edit"
                        className="cursor-pointer"
                    />
                    <span className="body-2">
                        {t('partners.back_to_reseller', { ns: 'partner' })}
                    </span>
                </div>
            </div>
            <Row className="contact-information mt-2">
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <div className="basic-information pr-2">
                        <div className="flex">
                            <div className="avatar">
                                <Avatar
                                    className="flex justify-center"
                                    src={
                                        partner?.avatarUrl
                                            ? getPublicMediaUrl(partner.avatarUrl)
                                            : '/images/avatar-placeholer.png'
                                    }
                                />
                            </div>

                            <div className="ml-8 overflow-hidden">
                                <div className="full-name break-words">{partner?.name}</div>
                                <div className="email-contact mt-1 break-words">
                                    <a href={`mailto:${partner?.email}`}>{partner?.email}</a>
                                </div>
                                <Space className="contact-social mt-5">
                                    {partner?.website ? (
                                        <a
                                            href={socialUrl('website', partner.website)}
                                            target="_blank"
                                        >
                                            <WebsiteIcon />
                                        </a>
                                    ) : (
                                        <WebsiteIcon />
                                    )}
                                    {partner?.twitter ? (
                                        <a
                                            href={socialUrl('twitter', partner.twitter)}
                                            target="_blank"
                                        >
                                            <TwitterIcon />
                                        </a>
                                    ) : (
                                        <TwitterIcon />
                                    )}
                                    {partner?.facebook ? (
                                        <a
                                            href={socialUrl('facebook', partner.facebook)}
                                            target="_blank"
                                        >
                                            <FacebookIcon />
                                        </a>
                                    ) : (
                                        <FacebookIcon />
                                    )}
                                </Space>
                            </div>
                        </div>
                        {(permissions || []).includes(PERMISSIONS.WL_CONTACT_EDIT) ? (
                            <Button
                                type="primary"
                                htmlType="button"
                                onClick={() => {
                                    editShow(id);
                                }}
                                className="edit-btn edit-profile"
                            >
                                {t('contacts.editProfile')}
                            </Button>
                        ) : null}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <div className="about-contact relative xl:pl-12 mt-3 xl:mt-0">
                        <div className="title">
                            {t('crm_internal.partner.about_reseller', { ns: 'common' })}
                        </div>
                        <div className="absolute divider-line left-0 hidden xl:block"></div>
                        <Row gutter={8}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className="break-words h-16">
                                    {renderField(
                                        t('contacts.fields.firstName.label'),
                                        partner?.firstName,
                                    )}
                                </div>

                                <div className="break-words">
                                    {renderField(
                                        t('contacts.fields.phoneNumber.label'),
                                        partner?.phone,
                                        true,
                                    )}
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className="break-words h-16">
                                    {renderField(
                                        t('contacts.fields.lastName.label'),
                                        partner?.lastName,
                                    )}
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div className="break-words h-16">
                                    {renderField(
                                        t('partners.fields.commissionRate.labelPercent', {
                                            ns: 'partner',
                                        }),
                                        partner?.marginReseller?.toString(),
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="insight">{id && <PartnerCrmInsights partnerId={id} />}</div>
            </Row>
        </>
    ) : (
        <Spin spinning={true}></Spin>
    );
};
