import BaseApi from './baseApi';

export class UploadService extends BaseApi {
    constructor() {
        super();
    }

    private readonly BASE_REPORT = 'v1/export';
    private readonly BASE_DISPUTES = 'v1/disputes';
    /* Refund Report */
    public async getReport(query: any): Promise<any> {
        const result = await this.get<any>(`${this.BASE_REPORT}`, query as any);

        return result?.data || null;
    }

    public async downloadImageEvidence(query: {
        evidenceId: string;
        disputeId: string;
    }): Promise<any> {
        const result = await this.get<any>(`${this.BASE_DISPUTES}/download`, query as any);

        return result;
    }
}
