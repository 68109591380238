import { IResourceComponentsProps } from '@refinedev/core';
import { FilterContextProvider } from 'contexts/filter.context';

import { PayoutListIndex } from './payouts';

export const PayoutReportIndex: React.FC<IResourceComponentsProps> = () => {
    return (
        <FilterContextProvider>
            <PayoutListIndex />
        </FilterContextProvider>
    );
};
